import {
  EngagementStatus,
  JobEngagementFragment,
} from '@upper/graphql/internal'

export type EngagementBasic = Pick<
  JobEngagementFragment,
  'id' | 'talent' | 'latestRevision'
>
export type EngagementRevisionBasic = Pick<
  JobEngagementFragment['latestRevision'],
  'status'
>

export type EngagementStatusGroupItem = {
  label: string
  statuses: EngagementStatus[]
  split?: { prefix: string; labels: string[] }
  engagements?: JobEngagementFragment[]
}
export type EngagementStatusSection = {
  name: string
  groups: EngagementStatusGroupItem[]
  expanded?: boolean
  expandable?: boolean
}

export type EngagementStatusGroup = EngagementStatusGroupItem[]

export enum EngagementRejectOptions {
  LackTechSkill = 'Lack of technical skills',
  LackSoftSkills = 'Lack of soft skills',
  LowBudget = 'Low budget',
  Counteroffer = 'Counteroffer',
  LackCommunication = 'Lack of communication',
  NotInterestedInProject = 'Not interested in the project',
  NotInterestedInCulture = 'Not interested in their culture',
  NoReply = 'No reply',
}

export enum EngagementCancelOptions {
  FilledInternally = 'Filled internally',
  FilledViaCompetition = 'Filled via competition',
  CandidateNotFit = 'Candidate not a fit',
  NeedNoMore = 'No need for talent any longer',
  NoCommunication = 'No communication/feedback',
  TalentNoLongerAvailable = 'Talent no longer available',
}
