import {
  EngagementRevisionActionNextSteps,
  EngagementRevisionActionStatus,
  EngagementStatus,
  EngagementType,
} from '@upper/graphql/internal'
import { words } from 'voca'
import {
  EngagementCancelOptions,
  EngagementRejectOptions,
  EngagementStatusSection,
} from '../types/engagement'

export const ENGAGEMENT_TYPE_OPTIONS = Object.keys(EngagementType).map((k) => ({
  label: words(EngagementType[k]).join(' '),
  value: k,
}))

export const rejectOptions = Object.values(EngagementRejectOptions).map(
  (e) => ({
    label: e,
    value: e,
  })
)

export const cancelOptions = Object.values(EngagementCancelOptions).map(
  (e) => ({
    label: e,
    value: e,
  })
)

export const TEMPLATE_GROUP_FOR_ENGAGEMENT: Partial<
  Record<EngagementStatus, string>
> = {
  [EngagementStatus.RejectedUpper]: 'rejection_upper',
  [EngagementStatus.RejectedFellow]: 'rejection_fellow',
  [EngagementStatus.RejectedClient]: 'rejection_client',
  [EngagementStatus.RejectedFreelancer]: 'rejection_talent',
}

export const ENGAGEMENT_STATUS_NAME: Partial<Record<EngagementStatus, string>> =
{
  CancelledClient: 'Cancelled by Client',
  CancelledFreelancer: 'Cancelled by Freelancer',
  CancelledUpper: 'Cancelled by UPPER',
  InterviewClient: 'Client Interview',
  InterviewFellow: 'Fellow Interview',
  ProposedClient: 'Proposed to Client',
  ProposedFellow: 'Proposed to Fellow',
  RejectedClient: 'Rejected by Client',
  RejectedFellow: 'Rejected by Fellow',
  RejectedFreelancer: 'Rejected by Freelancer',
  RejectedUpper: 'Rejected by UPPER',
  ExpertReview: 'Expert Review',
  //
  Applied: 'Applied',
  Ended: 'Ended',
  Hired: 'Hired',
  Inbox: 'Inbox',
  Invited: 'Invited',
  Matching: 'Matching',
  AcceptedByClient: 'Accepted by Client',
  Onboarding: 'Project Onboarding',
  Prepare: 'Prepare',
  ReadyToPropose: 'Ready to Propose',
  CreateProfile: 'Create Profile',
  Archived: 'Archived',
  ClientAssignment: 'Client Challenge',
}

export const ENGAGEMENT_PIPELINE: EngagementStatusSection[] = [
  {
    name: 'Talent Pool',
    groups: [
      {
        label: 'Talent Pool',
        statuses: [EngagementStatus.Inbox],
      },
    ],
  },
  {
    name: 'Sourcing',
    expandable: true,
    expanded: true,
    groups: [
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Invited],
        statuses: [EngagementStatus.Invited],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Applied],
        statuses: [EngagementStatus.Applied],
      },
    ],
  },
  {
    name: 'Matching',
    expandable: true,
    expanded: true,
    groups: [
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.ExpertReview],
        statuses: [EngagementStatus.ExpertReview],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Matching],
        statuses: [EngagementStatus.Matching],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Prepare],
        statuses: [EngagementStatus.Prepare],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.CreateProfile],
        statuses: [EngagementStatus.CreateProfile],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.ReadyToPropose],
        statuses: [EngagementStatus.ReadyToPropose],
      },
      {
        label: 'Proposed',
        statuses: [
          EngagementStatus.ProposedFellow,
          EngagementStatus.ProposedClient,
        ],
        split: { labels: ['Fellow', 'Client'], prefix: 'Proposed by' },
      },
      {
        label: 'Interview',
        statuses: [
          EngagementStatus.InterviewFellow,
          EngagementStatus.InterviewClient,
        ],
      },
      {
        label: 'Client Challenge',
        statuses: [EngagementStatus.ClientAssignment],
      },
      {
        label: 'Accepted by Client',
        statuses: [EngagementStatus.AcceptedByClient],
      },
    ],
  },
  {
    name: 'Active',
    expandable: true,
    expanded: true,
    groups: [
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Onboarding],
        statuses: [EngagementStatus.Onboarding],
      },
      {
        label: ENGAGEMENT_STATUS_NAME[EngagementStatus.Hired],
        statuses: [EngagementStatus.Hired],
      },
    ],
  },
  {
    name: 'Inactive',
    expandable: true,
    expanded: true,
    groups: [
      {
        label: 'Rejected',
        statuses: [
          EngagementStatus.RejectedFellow,
          EngagementStatus.RejectedClient,
          EngagementStatus.RejectedUpper,
          EngagementStatus.RejectedFreelancer,
        ],
        split: {
          labels: ['Fellow', 'Client', 'UPPER', 'Freelancer'],
          prefix: 'Rejected by',
        },
      },
      {
        label: 'Ended',
        statuses: [EngagementStatus.Ended],
      },
      {
        label: 'Cancelled',
        statuses: [
          EngagementStatus.CancelledUpper,
          EngagementStatus.CancelledFreelancer,
          EngagementStatus.CancelledClient,
        ],
        split: {
          labels: ['UPPER', 'Freelancer', 'Client'],
          prefix: 'Canceled by',
        },
      },
      {
        label: 'Archived',
        statuses: [EngagementStatus.Archived],
      },
    ],
  },
]

export const ENGAGEMENT_STATUS_ORDER = ENGAGEMENT_PIPELINE.map((s) =>
  s.groups.flatMap((sg) => sg.statuses)
).flat()

export const ENGAGEMENT_STATUS_OPTIONS = ENGAGEMENT_STATUS_ORDER.map((k) => {
  return { label: ENGAGEMENT_STATUS_NAME[k], value: k }
})

export const ENGAGEMENT_STATUS_OPTIONS_GROUPS = [
  {
    title: 'Talent Pool',
    statuses: [EngagementStatus.Inbox],
  },
  {
    title: 'Sourcing',
    statuses: [EngagementStatus.Invited, EngagementStatus.Applied],
  },
  {
    title: 'Matching',
    statuses: [
      EngagementStatus.ExpertReview,
      EngagementStatus.Matching,
      EngagementStatus.Prepare,
      EngagementStatus.CreateProfile,
      EngagementStatus.ReadyToPropose,
      EngagementStatus.ProposedFellow,
      EngagementStatus.InterviewFellow,
      EngagementStatus.ProposedClient,
      EngagementStatus.InterviewClient,
      EngagementStatus.ClientAssignment,
      EngagementStatus.AcceptedByClient
    ],
  },
  {
    title: 'Active',
    statuses: [EngagementStatus.Onboarding, EngagementStatus.Hired],
  },
  {
    title: 'Inactive',
    statuses: [
      EngagementStatus.RejectedClient,
      EngagementStatus.RejectedFellow,
      EngagementStatus.RejectedFreelancer,
      EngagementStatus.RejectedUpper,
      EngagementStatus.Ended,
      EngagementStatus.CancelledClient,
      EngagementStatus.CancelledFreelancer,
      EngagementStatus.CancelledUpper,
      EngagementStatus.Archived,
    ],
  },
]

export const ENGAGEMENT_STATUS_GROUPED_OPTIONS =
  ENGAGEMENT_STATUS_OPTIONS_GROUPS.map((g) => ({
    label: g.title,
    options: Object.values(g.statuses).map((s) => ({
      label: ENGAGEMENT_STATUS_NAME[s],
      value: s,
    })),
  }))

export const ENGAGEMENT_ACTION_STATUS_OPTIONS = Object.keys(
  EngagementRevisionActionStatus
).map((k) => ({
  label: words(EngagementRevisionActionStatus[k]).join(' '),
  value: k,
}))

export const ENGAGEMENT_NEXT_STEPS_OPTIONS = Object.keys(
  EngagementRevisionActionNextSteps
).map((k) => ({
  label: words(EngagementRevisionActionNextSteps[k]).join(' '),
  value: k,
}))
