import {
  FormikCheckbox,
  FormikCreatableMultiComboBox,
  FormikDateField,
  FormikRichTextEditor,
  FormikSelect,
  FormikSelectField,
  FormikTextField,
} from '@upper/formik'
import {
  HubspotJobsSearchDocument,
  HubspotJobsSearchQuery,
  JobContractType,
  JobDealSource,
  JobDealType,
  JobLocation,
  JobPriority,
  LanguagesDocument,
  PositionsDocument,
  RoleKey,
  SkillsSearchDocument,
  UsersDocument,
} from '@upper/graphql/internal'
import {
  Checkbox,
  CreatableSkillInfo,
  CreatableSkillPrompt,
  CreatableSkillTooltip,
  FormLabel,
  SelectField,
} from '@upper/ui'
import { formatName, pick } from '@upper/utils'
import { useField } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useClient, useQuery } from 'urql'
import { words } from 'voca'
import { SideFormSectionRow } from '../../../components/side-form-section-row'
import {
  COMMITMENT_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  JOB_DURATION,
  JOB_STATUS_OPTIONS,
  LOCATION_OPTIONS,
} from '../../../const/job'
import { FormSectionHeader } from './multi-step-manager'

type Props = {
  namePrefix?: string
  state?: 'add' | 'edit'
  showSection: (key: string) => void
  hideSection: (key: string) => void
}
/**
 * @name JobForm
 * @param {string} [namePrefix=job] - The prefix to add to the name of each input field
 */

function getOptionValue(option: any) {
  return option['id']
}
function getOptionLabel(option: any) {
  return option['name']
}
function getNewCreateOptionData(inputValue: any) {
  return {
    id: `create-${inputValue}`,
    name: `Add '${inputValue}' skill`,
    value: inputValue,
  }
}
function getNewOptionData(inputValue: any) {
  return {
    id: `create-${inputValue}`,
    name: inputValue,
  }
}

export function JobForm({
  namePrefix = 'job',
  state = 'add',
  showSection,
  hideSection,
}: Props) {
  const urqlClient = useClient()

  const [hubspotDeal, hubspotDealMeta, hubspotDealHelper] = useField(
    `${namePrefix}.hubspotDeal`
  )

  const [requiredSkills, , requiredSkillsHelper] = useField(
    `${namePrefix}.requiredSkills`
  )
  const [niceToHaveSkills, , niceToHaveSkillsHelper] = useField(
    `${namePrefix}.niceToHaveSkills`
  )

  const [location] = useField(`${namePrefix}.location`)
  const [hubspotCreate] = useField(`${namePrefix}.hubspotShouldCreate`)
  const [startDate, , startDateHelper] = useField(`${namePrefix}.startDate`)
  const [contractType] = useField(`${namePrefix}.contractType`)

  const [createRequiredSkill, setCreateRequiredSkill] = useState<string>()
  const [createNiceToHaveSkill, setCreateNiceToHaveSkill] = useState<string>()

  const handleLoadSkillOptions = useCallback(
    async (name: string) => {
      const result = await urqlClient
        .query(SkillsSearchDocument, {
          name: name,
          limit: 10,
        })
        .toPromise()

      return result.data?.searchSkills || []
    },
    [urqlClient]
  )

  const handleLoadLanguageOptions = useCallback(
    async (name: string) => {
      try {
        const result = await urqlClient
          .query(LanguagesDocument, {
            filters: { name },
          })
          .toPromise()
        return result.data.languages || []
      } catch (e) {
        console.log(e)
      }
    },
    [urqlClient]
  )

  const handleLoadHubspotOptions = useCallback(
    async (term: string) => {
      try {
        const result = await urqlClient
          .query(HubspotJobsSearchDocument, { term })
          .toPromise()

        return result.data?.jobsHubspotSearch?.results || []
      } catch (e) {
        return []
      }
    },
    [urqlClient]
  )

  const handleLoadEMOptions = useCallback(
    async (value) => {
      try {
        const result = await urqlClient
          .query(UsersDocument, {
            filters: { roles: [RoleKey.Em], name: value },
          })
          .toPromise()
        return (
          result.data?.users?.map((u) =>
            pick(u, 'id', 'firstName', 'lastName')
          ) || []
        )
      } catch (e) {
        console.log(e)
      }
    },
    [urqlClient]
  )

  const handleLoadPositionOptions = useCallback(
    async (name: string) => {
      const result = await urqlClient
        .query(PositionsDocument, { limit: 20, filters: { name: name } })
        .toPromise()
      return result.data?.positions || []
    },
    [urqlClient]
  )

  const [positionsResult] = useQuery({
    query: PositionsDocument,
    variables: { limit: 20 },
  })

  const positions = useMemo(
    () => positionsResult.data?.positions,
    [positionsResult.data?.positions]
  )

  const handleEnter = (key: string) => {
    showSection?.(key)
  }
  const handleLeave = (key: string) => {
    hideSection?.(key)
  }

  return (
    <>
      <FormSectionHeader
        id="details"
        onEnter={() => handleEnter('details')}
        onLeave={() => handleLeave('details')}
      >
        Details
      </FormSectionHeader>
      <div className="space-y-6">
        <div className="grid grid-cols-3 gap-6">
          <FormikTextField label="Name" name={`${namePrefix}.name`} required />
          <FormikSelectField
            name={`${namePrefix}.position`}
            label="Position"
            options={positions}
            getOptionLabel={(o: any) => o.name}
            getOptionValue={(o: any) => o.id}
            required
            menuWidth={'100%'}
            styles={{ menu: (b) => ({ ...b, zIndex: 2 }) }}
            isAsync
            loadOptions={handleLoadPositionOptions}
          />
          <FormikTextField
            label="# of people"
            name={`${namePrefix}.teamSize`}
            type={'number'}
            required
          />
        </div>
        <FormikRichTextEditor
          label="Description"
          name={`${namePrefix}.description`}
          required
          helpText="What will the person do?"
        />
      </div>
      <div className="space-y-6">
        <FormSectionHeader
          id="experience"
          onEnter={() => handleEnter('experience')}
          onLeave={() => handleLeave('experience')}
        >
          Experience
        </FormSectionHeader>
        {/* required skills */}
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-6">
            <div className="space-y-2">
              <FormikCreatableMultiComboBox
                name={`${namePrefix}.requiredSkills`}
                label="Required Skills"
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                getNewOptionData={getNewCreateOptionData}
                loadOptions={handleLoadSkillOptions}
                allowCreateWhileLoading={false}
                onCreateOption={(o) => {
                  setCreateRequiredSkill(o)
                }}
                required
                isCreated={(o) => getOptionValue(o)?.includes('create')}
                createdTooltip={(node) => <CreatableSkillTooltip node={node} />}
                formatCreateLabel={(o: any) => o?.value}
                creatablePromopt={
                  createRequiredSkill && (
                    <CreatableSkillPrompt
                      value={createRequiredSkill}
                      onAccept={() => {
                        requiredSkillsHelper.setValue([
                          ...(requiredSkills.value ?? []),
                          getNewOptionData(createRequiredSkill),
                        ])
                        setCreateRequiredSkill(undefined)
                      }}
                      onDeny={() => setCreateRequiredSkill(undefined)}
                    />
                  )
                }
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: '200px',
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                // menuPortalTarget={document.body}
                helpText="(e.g. Javascript, React, Node, etc)"
              />
              {requiredSkills.value?.some((rs) =>
                rs.id?.startsWith('create')
              ) && (
                <CreatableSkillInfo
                  skills={requiredSkills?.value
                    ?.filter((rs) => rs.id?.startsWith('create'))
                    ?.map((rs) => rs.name ?? '')}
                />
              )}
            </div>
          </div>
          <div className="space-y-2">
            <FormikCreatableMultiComboBox
              name={`${namePrefix}.niceToHaveSkills`}
              label="Nice to have skills"
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              getNewOptionData={getNewCreateOptionData}
              loadOptions={handleLoadSkillOptions}
              onCreateOption={(o) => {
                setCreateNiceToHaveSkill(o)
              }}
              isCreated={(o) => getOptionValue(o)?.includes('create')}
              createdTooltip={(node) => <CreatableSkillTooltip node={node} />}
              formatCreateLabel={(o: any) => o?.value}
              creatablePromopt={
                createNiceToHaveSkill && (
                  <CreatableSkillPrompt
                    value={createNiceToHaveSkill}
                    onAccept={() => {
                      niceToHaveSkillsHelper.setValue([
                        ...niceToHaveSkills.value,
                        getNewOptionData(createNiceToHaveSkill),
                      ])
                      setCreateNiceToHaveSkill(undefined)
                    }}
                    onDeny={() => setCreateNiceToHaveSkill(undefined)}
                  />
                )
              }
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 10,
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: '200px',
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              helpText="(e.g. Fintech experience, E-commerce platforms, etc)"
            />
            {niceToHaveSkills.value?.some((rs) =>
              rs.id?.startsWith('create')
            ) && (
              <CreatableSkillInfo
                skills={niceToHaveSkills?.value
                  ?.filter((rs) => rs.id?.startsWith('create'))
                  ?.map((rs) => rs.name ?? '')}
              />
            )}
          </div>
          <FormikRichTextEditor
            label="Mandatory Experience"
            name={`${namePrefix}.requiredExperience`}
          />
          <FormikRichTextEditor
            label="Good to have Experience"
            name={`${namePrefix}.niceToHaveExperience`}
          />
        </div>
      </div>
      {/* freelancer */}
      <div className="space-y-6">
        <FormSectionHeader
          id="freelancer"
          onEnter={() => handleEnter('freelancer')}
          onLeave={() => handleLeave('freelancer')}
        >
          Freelancer details
        </FormSectionHeader>
        <FormikRichTextEditor
          label="Acceptance process"
          name={`${namePrefix}.acceptanceProcess`}
          helpText="(e.g. Interview with CTO, technical test, etc)"
        />
        <div className="grid grid-cols-2 gap-6">
          <FormikTextField
            label="Goals & desired outcome of role"
            name={`${namePrefix}.roleGoals`}
            helpText="(e.g. Speed up delivery, Reach a deadline, etc)"
          />

          <FormikTextField
            name={`${namePrefix}.mindset`}
            label="Ideal “mindset” traits"
            helpText="(e.g. Communication, Ownership, Pragmatic, Leader, etc)"
          />
        </div>
        <FormikSelectField
          isAsync
          isMulti
          label="Languages"
          name={`${namePrefix}.languages`}
          loadOptions={handleLoadLanguageOptions}
          getOptionLabel={(o: any) => o.name}
          getOptionValue={(o: any) => o.id}
          required
          styles={{ menu: (b) => ({ ...b, zIndex: 2 }) }}
        />
        <div className="grid grid-cols-3 gap-6">
          <FormikSelectField
            label="Location"
            name={`${namePrefix}.location`}
            options={LOCATION_OPTIONS}
            asArrayOfValues
            required
            styles={{ menu: (b) => ({ ...b, zIndex: 2 }) }}
          />
          {location.value === JobLocation.OnSite && (
            <SideFormSectionRow>
              <FormikTextField
                name={`${namePrefix}.locationOnSite`}
                label="On-Site Location"
              />
            </SideFormSectionRow>
          )}
          {location.value === JobLocation.Hybrid && (
            <>
              <FormikTextField
                name={`${namePrefix}.locationOnSite`}
                label="On-Site Location"
              />
              <FormikTextField
                name={`${namePrefix}.locationOnSitePercentage`}
                label="On-Site percentage"
                suffix="%"
              />
            </>
          )}
        </div>
      </div>
      {/* more details */}
      <div className="space-y-6">
        <FormSectionHeader
          id="engagement"
          onEnter={() => handleEnter('engagement')}
          onLeave={() => handleLeave('engagement')}
        >
          Engagement
        </FormSectionHeader>
        <div className="grid grid-cols-3 gap-6">
          <FormikSelectField
            label="Contract type"
            name={`${namePrefix}.contractType`}
            options={CONTRACT_TYPE_OPTIONS}
            required
            asArrayOfValues
          />
          <FormikSelectField
            label="Commitment"
            name={`${namePrefix}.commitment`}
            options={COMMITMENT_OPTIONS}
            required
            asArrayOfValues
          />
          <FormikTextField
            label="Commitment Info"
            name={`${namePrefix}.commitmentInfo`}
          />
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <FormLabel>Start</FormLabel>
            <Checkbox
              checked={startDate.value === null}
              onChange={(e) =>
                startDateHelper.setValue(startDate.value === null ? '' : null)
              }
              className="mt-6"
            >
              Start ASAP
            </Checkbox>
          </div>

          {startDate.value !== null && (
            <FormikDateField
              label="Start date"
              name={`${namePrefix}.startDate`}
              disablePast
            />
          )}
          <FormikSelect label="Duration" name={`${namePrefix}.length`}>
            {JOB_DURATION.map((o) => (
              <option key={o.value as string} value={o.value as string}>
                {o.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <FormikSelectField
            className="col-span-2"
            name={`${namePrefix}.em`}
            label="EM"
            isAsync
            isSearchable
            required
            getOptionLabel={(o: any) => formatName(o)}
            getOptionValue={(o: any) => o.id}
            loadOptions={handleLoadEMOptions}
            styles={{ menu: (b) => ({ ...b, zIndex: 2 }) }}
          />
        </div>
      </div>
      {/* budget */}
      <div className="space-y-6">
        <FormSectionHeader
          id="budget"
          onEnter={() => handleEnter('budget')}
          onLeave={() => handleLeave('budget')}
        >
          Budget
        </FormSectionHeader>
        <div>
          {contractType.value !== JobContractType.Employment ? (
            <FormikTextField
              type={'number'}
              name={`${namePrefix}.hourlyRateBudget`}
              label="Maximum client rate"
              prefix="€"
              suffix="/h"
            />
          ) : (
            <FormikTextField
              type={'number'}
              name={`${namePrefix}.annualSalaryBudget`}
              label="Annual salary budget"
              prefix="€"
              suffix="/year"
            />
          )}
        </div>
      </div>
      {/* team */}
      <div className="space-y-6">
        <FormSectionHeader
          id="more-details"
          onEnter={() => handleEnter('more-details')}
          onLeave={() => handleLeave('more-details')}
        >
          More details
        </FormSectionHeader>
        <SideFormSectionRow>
          <FormikSelect name={`${namePrefix}.priority`} label="Priority">
            {Object.keys(JobPriority).map((k) => (
              <option key={k} value={k}>
                {JobPriority[k]}
              </option>
            ))}
          </FormikSelect>
        </SideFormSectionRow>
        <SideFormSectionRow>
          <div className="grid grid-cols-2 gap-6">
            <FormikCheckbox name={`${namePrefix}.isPausedSourcing`}>
              Pause sourcing
            </FormikCheckbox>
          </div>
        </SideFormSectionRow>
        <SideFormSectionRow>
          <FormikSelectField
            label="Status"
            name={`${namePrefix}.status`}
            options={JOB_STATUS_OPTIONS}
            asArrayOfValues
            styles={{ menu: (b) => ({ ...b, zIndex: 2 }) }}
          />
        </SideFormSectionRow>
        {/* integrations */}
        <FormSectionHeader
          id="hubspot"
          onEnter={() => handleEnter('hubspot')}
          onLeave={() => handleLeave('hubspot')}
        >
          Hubspot
        </FormSectionHeader>
        {state === 'add' && (
          <div className="grid grid-cols-2 gap-6">
            <FormikSelect
              label="Deal Source"
              name={`${namePrefix}.dealSource`}
              required
            >
              <option value={null}></option>
              {Object.keys(JobDealSource).map((k) => (
                <option key={k} value={JobDealSource[k]}>
                  {words(JobDealSource[k]).join(' ')}
                </option>
              ))}
            </FormikSelect>
            <FormikSelect
              label="Deal Type"
              name={`${namePrefix}.dealType`}
              required
            >
              <option value={null}></option>
              {Object.keys(JobDealType).map((k) => (
                <option key={k} value={JobDealType[k]}>
                  {words(JobDealType[k]).join(' ')}
                </option>
              ))}
            </FormikSelect>
          </div>
        )}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <FormLabel>Hubspot Deal</FormLabel>
            <FormikCheckbox
              name={`${namePrefix}.hubspotShouldCreate`}
              required
              className="mt-6"
            >
              Create new Hubspot Deal
            </FormikCheckbox>
          </div>
          <SelectField
            isAsync
            label="Select Hubspot deal"
            getOptionLabel={(o: any) => o.name}
            getOptionValue={(o: any) => o.id}
            loadOptions={handleLoadHubspotOptions}
            value={hubspotDeal.value}
            onChange={(o) => {
              if (o)
                hubspotDealHelper.setValue(
                  (o as HubspotJobsSearchQuery['jobsHubspotSearch']['results'][0]) ??
                    null
                )
              else hubspotDealHelper.setValue(null)
            }}
            invalidMessage={hubspotDealMeta.error}
            isClearable
            required
            menuWidth={300}
            maxMenuHeight={120}
            isDisabled={hubspotCreate.value}
          />
        </div>
      </div>
    </>
  )
}
