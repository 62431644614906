import { ProjectsForListDocument } from '@upper/graphql/internal'
import { Button, Tabs, TabsList, TabsTrigger } from '@upper/sapphire/ui'
import { SelectField } from '@upper/ui'
import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useClient } from 'urql'
import { snakeCase, upperCase } from 'voca'
import { ActorRefFrom, SnapshotFrom } from 'xstate'
import multiStepMainForm from '../../../state-machine/multi-step-main-form'
import { JobProjectForm } from './project-form'

type ProjectStepProps = {
  state: {
    matches: (value: string) => boolean
    context: SnapshotFrom<typeof multiStepMainForm>['context']
  }
  send: ActorRefFrom<typeof multiStepMainForm>['send']
  showSection: (value: string) => void
  hideSection: (value: string) => void
  registerRef: (value: string, ref: HTMLDivElement | null) => void
  canContinue: boolean
}
export function ProjectStep({
  state,
  send,
  showSection,
  hideSection,
  registerRef,
  canContinue,
}: ProjectStepProps) {
  const client = useClient()
  const { values, setFieldValue } = useFormikContext<any>()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    registerRef('projectStep', ref.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} className="pb-10">
      <header
        className={twMerge(
          'sticky top-0 z-10 mt-60 mb-5 w-full space-y-3 rounded-xl p-1 transition-all duration-300',
          !state.matches('projectStep.choose') &&
            !state.matches('projectStep.selectExisting') &&
            'mt-0 mb-0 p-0'
        )}
      >
        {(state.matches('projectStep.choose') ||
          state.matches('projectStep.selectExisting')) && (
          <>
            <h3 className="text-center text-2xl font-semibold text-blue-700">
              Select or Create Project
            </h3>
            <p className="mx-auto max-w-md text-center text-sm">
              {state.context.companyStep === 'selectExisting' ? (
                <>
                  Select an existing project associated with the company or
                  create a new one. Projects act as containers for your jobs.
                </>
              ) : (
                <>
                  Create a new project for your job. Projects act as containers
                  for your jobs.
                </>
              )}
            </p>
          </>
        )}
      </header>
      <Tabs
        value={state.context.projectStep}
        onValueChange={(value) => {
          send({
            type: upperCase(snakeCase(value)),
          })
        }}
        className="sticky top-0 z-[5] mx-auto mb-5 w-full max-w-md"
      >
        {(state.context.state === 'edit' ||
          state.context.companyStep === 'selectExisting') && (
          <TabsList className="shadow-outer-stroke w-full gap-1 bg-white">
            {(state.context.state === 'edit' ||
              state.context.companyStep === 'selectExisting') && (
              <TabsTrigger
                value="selectExisting"
                className="flex w-full items-center justify-center gap-2 bg-slate-50 !shadow-none hover:bg-white active:bg-blue-50 active:text-blue-700"
              >
                Select Existing Project
              </TabsTrigger>
            )}
            <TabsTrigger
              value="createNew"
              className="flex w-full items-center justify-center gap-2 bg-slate-50 !shadow-none hover:bg-white active:bg-green-50 active:text-green-700"
            >
              Create New Project
            </TabsTrigger>
            {state.context.state === 'edit' && (
              <TabsTrigger
                value="edit"
                className="flex w-full items-center justify-center gap-2 bg-slate-50 !shadow-none hover:bg-white active:bg-yellow-50 active:text-yellow-700"
              >
                Edit Project
              </TabsTrigger>
            )}
          </TabsList>
        )}
      </Tabs>
      {state.matches('projectStep.selectExisting') && (
        <div className="mx-auto max-w-md space-y-5">
          <SelectField
            label="Select Project"
            name="project"
            placeholder="Select Project"
            value={{
              label: values.project.selected?.name,
              value: values.project.selected?.id,
            }}
            options={[]}
            onChange={(e: any) => {
              setFieldValue('project.selected.id', e.value)
              setFieldValue('project.selected.name', e.label)
            }}
            loadOptions={async (input) => {
              const companies = await client.query(ProjectsForListDocument, {
                filters: {
                  name: input,
                  company: { id: (values as any)?.company?.selected?.id ?? '' },
                },
              })
              if (companies.data.projects.length === 0) {
                return []
              }
              return companies.data.projects.map((project) => ({
                label: project.name,
                value: project.id,
              }))
            }}
            isAsync
          />
        </div>
      )}
      {state.matches('projectStep.createNew') && (
        <div className="space-y-5 px-3">
          <JobProjectForm
            companyId={(values as any)?.company?.selected?.id}
            namePrefix="project.add"
            showSection={showSection}
            hideSection={hideSection}
          />
        </div>
      )}
      {state.matches('projectStep.edit') && (
        <div className="space-y-5 px-3">
          <JobProjectForm
            companyId={(values as any)?.company?.selected?.id}
            namePrefix="project.edit"
            showSection={showSection}
            hideSection={hideSection}
          />
        </div>
      )}
      <Button
        onClick={() => send({ type: 'NEXT' })}
        className="mx-auto mt-5 flex w-40"
        disabled={!canContinue}
      >{`Continue to Job`}</Button>
    </div>
  )
}
