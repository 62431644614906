import { AnimatePresence, motion } from 'framer-motion'
import { CheckIcon } from 'lucide-react'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'
import { StepBadge } from './step-badge'

const STEP_BADGE_COLORS = {
  createNew: 'text-green-600',
  selectExisting: 'text-blue-600',
  edit: 'text-yellow-600',
}

const VALID_STEP_COLORS = {
  createNew: 'border-green-300 !ring-green-300',
  selectExisting: 'border-blue-300 !ring-blue-300',
  edit: 'border-yellow-300 !ring-yellow-300',
}

export function StepButton({
  label,
  name,
  children,
  step,
  active,
  onClick,
  isEnabled = true,
  isValid,
}: PropsWithChildren<{
  label: string
  name: string
  step?: null | 'createNew' | 'selectExisting' | 'choose' | 'edit'
  active?: boolean
  onClick?: () => void
  isEnabled?: boolean
  isValid?: boolean
}>) {
  return (
    <div
      className={twMerge(
        'transition-all duration-300',
        'w-full overflow-clip rounded-lg border border-slate-200 bg-white p-0 ring-0 text-slate-600',
        // active && 'shadow-outer-stroke bg-slate-50 text-slate-600'
        active && 'border-slate-300 text-slate-800 ring-1 ring-slate-300',
        !isEnabled && 'pointer-events-none bg-slate-50 text-slate-500',
        // isValid && VALID_STEP_COLORS[step]
      )}
    >
      <button
        onClick={onClick}
        className={twMerge(
          'sticky top-0 z-[1] transition-all duration-300',
          'flex w-full items-center justify-start gap-2 rounded-md px-3 py-2',
          !isEnabled && 'pointer-events-none'
        )}
      >
        {/* {step === 'createNew' && <PlusCircleIcon className="h-4 w-4" />}
        {step === 'selectExisting' && <FolderIcon className="h-4 w-4" />} */}
        <div className="flex w-full flex-col leading-none">
          <div className="flex h-5 w-full items-center gap-1">
            <span>{name}</span>
            <AnimatePresence>
              {isValid && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={twMerge(
                    'h-4 w-4 rounded-full bg-green-400/20 p-[3px] text-green-600'
                  )}
                >
                  <CheckIcon className={twMerge('h-2.5 w-2.5')} />
                </motion.span>
              )}
            </AnimatePresence>
            <span className="ml-auto inline-flex items-center gap-1">
              {step !== 'choose' && <StepBadge step={step} />}
            </span>
          </div>
          <span
            className={twMerge(
              'text-xs text-slate-600',
              STEP_BADGE_COLORS[step]
            )}
          >
            {label || '...'}
          </span>
        </div>
      </button>
      <AnimatePresence>
        {active && (step === 'createNew' || step === 'edit') && children}
      </AnimatePresence>
    </div>
  )
}
