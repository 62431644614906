import {
  JobDocument,
  JobsSelectDocument,
  JobsSelectQuery,
  JobStatus,
} from '@upper/graphql/internal'
import { CrossIcon, SearchIcon } from '@upper/icons'
import { classNames, TextField } from '@upper/ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { useDebounce } from 'use-debounce'
import { SearchListItem } from '../components/search-list-item'

type Props = {
  jobId?: string
  onChange?: (id: string | undefined) => void
}

export const JobFinder = ({ jobId, onChange }: Props) => {
  const [jobName, setJobName] = useState<string>('')
  const [debouncedJobName] = useDebounce(jobName, 250)
  const [job, setJob] = useState<JobsSelectQuery['jobs'][0]>()

  const [jobsSelectResult] = useQuery({
    query: JobsSelectDocument,
    variables: {
      filters: {
        globalName: debouncedJobName,
        statuses: [JobStatus.InStaffing],
      },
    },
    pause: jobName === '',
  })

  const [jobResult] = useQuery({
    query: JobDocument,
    variables: { id: jobId },
    pause: !jobId,
  })

  const handleJobSelect = useCallback(
    (j) => {
      setJob(j)
      onChange && onChange(j.id)
    },
    [onChange]
  )

  const handleJobNameClear = useCallback(() => {
    setJobName('')
    setJob(undefined)
    onChange && onChange(undefined)
  }, [onChange])

  const handleJobClear = useCallback(() => {
    setJob(undefined)
    onChange && onChange(undefined)
  }, [onChange])

  const jobs =
    jobName !== '' && jobsSelectResult.data ? jobsSelectResult.data.jobs : []

  useEffect(() => {
    if (jobResult.data?.job) {
      setJob(jobResult.data?.job)
    }
  }, [jobResult])

  return (
    <>
      {job && (
        <div
          className={classNames(
            'flex items-center rounded-xl px-3 py-2 border',
            'bg-white border-blue-light'
          )}
        >
          <div>
            <h6 className={classNames('text-blue')}>{job.name}</h6>
            {job.company && (
              <p className={classNames('text-sm', 'text-gray-darkest')}>
                {job.company.name}
              </p>
            )}
          </div>
          <button
            className="text-gray-dark p-2 ml-auto -mr-2 hover:text-gray-darkest"
            onClick={handleJobClear}
          >
            <CrossIcon />
          </button>
        </div>
      )}
      {!job && (
        <div>
          <TextField
            startAddon={<SearchIcon />}
            name="job"
            value={jobName}
            placeholder="Search by job or company name"
            clearable
            onChange={(e) => setJobName(e.target.value)}
            onClear={handleJobNameClear}
          />
        </div>
      )}
      <div className="mt-2">
        {jobs &&
          !job &&
          jobs.map((j) => (
            <SearchListItem
              key={j.id}
              title={j.name}
              subtitle={j.company?.name}
              onClick={() => handleJobSelect(j)}
            />
          ))}
      </div>
    </>
  )
}
