import { CrossIcon } from '@upper/icons'
import { classNames } from '@upper/ui'
import { HTMLAttributes, ReactNode } from 'react'

type Props = {
  title: string
  subtitle?: string
  active?: boolean
  extra?: ReactNode
  closable?: boolean
  onClose?: () => void
} & HTMLAttributes<HTMLElement>
export const SearchListItem = ({
  title,
  subtitle,
  className,
  active,
  extra,
  closable,
  onClose,
  ...props
}: Props) => {
  const Element: React.ElementType = closable ? 'div' : 'button'
  return (
    <Element
      type="button"
      className={classNames(
        'group flex items-center w-full bg-white rounded-md px-3 py-2 border-gray-light border mb-1 last:mb-0 hover:border-blue cursor-pointer transition-all',
        active && 'border-blue',
        className
      )}
      {...props}
    >
      <div className="text-left text-gray-dark w-full">
        <div className="flex items-baseline gap-3">
          <h6
            className={classNames(
              'group-hover:text-blue',
              active && 'text-blue'
            )}
          >
            {title}
          </h6>
          {extra && <span className="ml-auto">{extra}</span>}
        </div>
        {subtitle && (
          <p
            className={classNames(
              'text-sm',
              active ? 'text-gray-darkest' : 'text-gray'
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
      {closable && (
        <span className="ml-auto">
          <button className="p-3 text-gray hover:text-red" onClick={onClose}>
            <CrossIcon />
          </button>
        </span>
      )}
    </Element>
  )
}
