import { CreateSkillDocument, SkillInput } from '@upper/graphql/internal';
import { useClient } from 'urql';

export function useCreateSkills() {
  const urqlClient = useClient()

  const createSkills = async (data: SkillInput[]) => {
    if (createSkills.length > 0) {
      return await Promise.all(
        data.filter((s) => s.id?.startsWith('create')).map((s) =>
          urqlClient
            .mutation(CreateSkillDocument, {
              data: { name: s.name },
            })
            .toPromise()
        )
      ).then((result) => {
        return result.map((r) => ({
          id: r.data?.createSkill?.id,
          name: r.data?.createSkill?.name,
        }))
      })
    }
  }
  return { createSkills }
}