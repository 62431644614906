/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TooltipProvider } from '@radix-ui/react-tooltip'
import {
  AuthProvider,
  createUrqlClient,
  WithAuthRedirect,
  WithRoleCheck,
} from '@upper/auth'
import { RoleKey } from '@upper/graphql/auth'
import {
  BaseTimesheetDayFragmentDoc,
  BaseTimesheetFragmentDoc,
  BulkApproveTimesheetMutation,
  CreateAbsenceMutation,
  CreateExpenseMutation,
  CreateTimeEntryMutation,
  CreateTimesheetDayMutation,
  DeleteAbsenceMutation,
  DeleteExpenseMutation,
  DeleteTimeEntryMutation,
  MutationBulkApproveTimesheetArgs,
  MutationCreateAbsenceArgs,
  MutationCreateExpenseArgs,
  MutationCreateTimeEntryArgs,
  MutationCreateTimesheetDayArgs,
  MutationDeleteAbsenceArgs,
  MutationDeleteExpenseArgs,
  MutationDeleteTimeEntryArgs,
  MutationSubmitTimesheetArgs,
  MutationUpdateExpenseArgs,
  MutationUpdateTalentAssessmentArgs,
  MutationUpdateTalentStatusArgs,
  MutationUpdateTimeEntryArgs,
  QueryTalentsArgs,
  QueryTimesheetsArgs,
  TalentForVettingFragmentDoc,
  TalentListDocument,
  TalentUpdateStatusMutation,
  TimesheetDayStatus,
  TimesheetsDocument,
  TimesheetSubmitMutation,
  UpdateExpenseMutation,
  UpdateTimeEntryMutation,
} from '@upper/graphql/internal'
import { ModalsProvider } from '@upper/providers'
import '@upper/sapphire/ui/style'
import { stringifyVariables } from '@urql/core'
import {
  cacheExchange,
  NullArray,
  Resolver,
  Variables,
} from '@urql/exchange-graphcache'
import { formatISO } from 'date-fns'
import Head from 'next/head'
import Script from 'next/script'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'urql'
import { AddFreelancerDrawer } from '../fragments/freelancers/drawer/add-freelancer'
import { MultiStepManager } from '../fragments/job/multi-step-form/multi-step-manager'
import { NavigationProvider } from '../providers'
import { AccessProvider } from '../providers/access'
import { AppPropsWithAuth } from '../types/app'
import './styles.css'

export type MergeMode = 'before' | 'after'

/** Input parameters for the {@link simplePagination} factory. */
export interface PaginationParams {
  /** The name of the field argument used to define the page’s offset. */
  offsetArgument?: string
  /** The name of the field argument used to define the page’s length. */
  limitArgument?: string
  /** Flip between forward and backwards pagination.
   *
   * @remarks
   * When set to `'after'`, its default, pages are merged forwards and in order.
   * When set to `'before'`, pages are merged in reverse, putting later pages
   * in front of earlier ones.
   */
  mergeMode?: MergeMode
}
const APP_NAME = 'internal'
const simplePagination = ({
  offsetArgument = 'offset',
  limitArgument = 'limit',
  mergeMode = 'after',
}: PaginationParams = {}): Resolver<any, any, any> => {
  const compareArgs = (
    fieldArgs: Variables,
    connectionArgs: Variables
  ): boolean => {
    for (const key in connectionArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue
      } else if (!(key in fieldArgs)) {
        return false
      }

      const argA = fieldArgs[key]
      const argB = connectionArgs[key]

      if (
        typeof argA !== typeof argB || typeof argA !== 'object'
          ? argA !== argB
          : stringifyVariables(argA) !== stringifyVariables(argB)
      ) {
        return false
      }
    }

    for (const key in fieldArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue
      }
      if (!(key in connectionArgs)) return false
    }

    return true
  }

  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info

    const allFields = cache.inspectFields(entityKey)
    const fieldInfos = allFields.filter((info) => info.fieldName === fieldName)

    const size = fieldInfos.length
    if (size === 0) {
      return undefined
    }

    const visited = new Set()
    let result: NullArray<string> = []
    let prevOffset: number | null = null

    for (let i = 0; i < size; i++) {
      const { fieldKey, arguments: args } = fieldInfos[i]
      if (args === null || !compareArgs(fieldArgs, args)) {
        continue
      }

      const link = cache.resolve(entityKey, fieldKey) as string
      const currentOffset = args[offsetArgument]

      if (
        link === null ||
        link.length === 0 ||
        typeof currentOffset !== 'number'
      ) {
        continue
      }

      const tempResult: NullArray<string> = []

      if (!visited.has(link)) {
        tempResult.push(link)
        visited.add(link)
      }

      if (
        (!prevOffset || currentOffset > prevOffset) ===
        (mergeMode === 'after')
      ) {
        result = [...result, ...tempResult]
      } else {
        result = [...tempResult, ...result]
      }

      prevOffset = currentOffset
    }

    const hasCurrentPage = cache.resolve(entityKey, fieldName, fieldArgs)

    if (hasCurrentPage) {
      return result
    } else if (!(info as any).store.schema) {
      return undefined
    } else {
      info.partial = true
      return result
    }
  }
}

const urqlClient = createUrqlClient(
  APP_NAME,
  cacheExchange({
    keys: {
      talentList: null,
    },
    directives: {
      simplePagination,
    },
    resolvers: {
      Query: {
        timesheets: simplePagination({ offsetArgument: 'offset' }),
        talentList: simplePagination({ offsetArgument: 'offset' }),
      },
    },
    optimistic: {
      updateTalentAssessment(
        args: MutationUpdateTalentAssessmentArgs,
        cache,
        info
      ) {
        return {
          __typename: 'TalentAssessment',
          id: args.id,
          status: args.data.status,
          conclusion: args.data.conclusion,
          notes: args.data.notes,
        }
      },
    },
    updates: {
      Mutation: {
        updateTalentStatus: (
          result: TalentUpdateStatusMutation,
          args: MutationUpdateTalentStatusArgs,
          cache,
          info
        ) => {
          const talentFragment = cache.readFragment(
            TalentForVettingFragmentDoc,
            { id: args.id }
          )

          if (talentFragment) {
            cache
              .inspectFields('Query')
              .filter((field) => field.fieldName === 'talentList')
              .forEach((field: { arguments: QueryTalentsArgs }) => {
                console.log(field)

                cache.updateQuery(
                  {
                    query: TalentListDocument,
                    variables: field.arguments,
                  },
                  (data: any | null) => {
                    if (!data) return null
                    if (
                      field.arguments.filters.statuses?.includes(
                        args.data.status
                      )
                    ) {
                      data.talentList?.results?.splice(0, 0, talentFragment)
                      data.talentList.total = data.talentList?.total + 1
                      // alreadyAdded = true
                    } else {
                      data.talentList.results =
                        data.talentList?.results?.filter(
                          (t) => t.status !== talentFragment.status
                        )
                      data.talentList.total = data.talentList?.total - 1
                    }
                    return data
                  }
                )
              })
          }
        },
        bulkApproveTimesheet: (
          result: BulkApproveTimesheetMutation,
          args: MutationBulkApproveTimesheetArgs,
          cache,
          info
        ) => {
          cache
            .inspectFields('Query')
            .filter(
              (field: { arguments: QueryTimesheetsArgs; fieldName: string }) =>
                field.fieldName === 'timesheets' &&
                formatISO(new Date(field.arguments.filters.startDate), {
                  representation: 'date',
                }) === args.filters.startDate &&
                formatISO(new Date(field.arguments.filters.endDate), {
                  representation: 'date',
                }) === args.filters.endDate
            )
            .forEach((field) => {
              const fragment = cache.readQuery({
                query: TimesheetsDocument,
                variables: field.arguments,
              })

              if (!fragment) return
              const days = fragment.timesheets.flatMap((t) => t.days).flat()
              days.forEach((d) => {
                cache.writeFragment(BaseTimesheetDayFragmentDoc, {
                  id: d.id,
                  status: TimesheetDayStatus.Approved,
                })
              })
            })
        },
        updateTimeEntry: (
          result: UpdateTimeEntryMutation,
          args: MutationUpdateTimeEntryArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: result.updateTimeEntry?.timesheetDayId,
          })
          if (fragment) {
            fragment.totalHours = fragment.timeEntries?.reduce((acc, v) => {
              return (acc += v.hours ?? 0)
            }, 0)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        updateExpense: (
          result: UpdateExpenseMutation,
          args: MutationUpdateExpenseArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: result.updateExpense?.timesheetDayId,
          })
          if (fragment) {
            fragment.totalExpenses = fragment.expenses?.reduce((acc, v) => {
              return (acc += v.amount ?? 0)
            }, 0)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        submitTimesheet: (
          result: TimesheetSubmitMutation,
          args: MutationSubmitTimesheetArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
            __typename: 'Timesheet',
            id: args.engagementId,
          })
          if (fragment) {
            result.submitTimesheet.days?.map((td) => {
              if (!fragment.days?.find((fd) => fd.id === td.id))
                fragment.days?.push(td)
            })
            cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
          }
        },
        createTimesheetDay: (
          result: CreateTimesheetDayMutation,
          args: MutationCreateTimesheetDayArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
            __typename: 'Timesheet',
            id: args.data.engagementId,
          })
          if (fragment) {
            fragment.days?.push(result.createTimesheetDay)
            cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
          }
        },
        createTimeEntry: (
          result: CreateTimeEntryMutation,
          args: MutationCreateTimeEntryArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment.timeEntries.push(result.createTimeEntry)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteTimeEntry: (
          result: DeleteTimeEntryMutation,
          args: MutationDeleteTimeEntryArgs,
          cache,
          info
        ) => {
          if (result.deleteTimeEntry)
            cache.invalidate({
              __typename: 'TimeEntry',
              id: args.id,
            })
        },
        createAbsence: (
          result: CreateAbsenceMutation,
          args: MutationCreateAbsenceArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment.absences.push(result.createAbsence)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteAbsence: (
          result: DeleteAbsenceMutation,
          args: MutationDeleteAbsenceArgs,
          cache,
          info
        ) => {
          if (result.deleteAbsence)
            cache.invalidate({
              __typename: 'Absence',
              id: args.id,
            })
        },
        createExpense: (
          result: CreateExpenseMutation,
          args: MutationCreateExpenseArgs,
          cache,
          info
        ) => {
          const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
            __typename: 'TimesheetDay',
            id: args.data.timesheetDayId,
          })
          if (fragment) {
            fragment.expenses.push(result.createExpense)
            cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
          }
        },
        deleteExpense: (
          result: DeleteExpenseMutation,
          args: MutationDeleteExpenseArgs,
          cache,
          info
        ) => {
          if (result.deleteExpense)
            cache.invalidate({
              __typename: 'Expense',
              id: args.id,
            })
        },
      },
    },
  })
)

function InternalApp({ Component, pageProps, router }: AppPropsWithAuth) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#0F44D7" />
      </Head>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_INTERNAL}');
          `,
        }}
      />
      <Provider value={urqlClient}>
        <AuthProvider appName={APP_NAME}>
          <WithAuthRedirect
            authenticate={Component.authenticate}
            redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          >
            <WithRoleCheck roles={RoleKey.Admin}>
              <TooltipProvider>
                <ModalsProvider>
                  <NavigationProvider>
                    {/* modals */}
                    {/* <AddJobDrawer /> */}
                    {/* <JobAddDrawer /> */}
                    {/* <EditJobDrawer /> */}
                    {/* <JobEditDrawer /> */}
                    {/* <JobManagerDialog /> */}
                    <MultiStepManager />
                    <AddFreelancerDrawer />
                    {/* app */}
                    <Head>
                      <title>UPPER - Internal</title>
                    </Head>
                    <main>
                      <AccessProvider
                        restrict={[
                          {
                            path: '/engagements',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/engagements/*',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/timesheets',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/timesheets/*',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                        ]}
                      >
                        {getLayout(<Component {...pageProps} />)}{' '}
                      </AccessProvider>
                    </main>
                  </NavigationProvider>
                </ModalsProvider>
              </TooltipProvider>
            </WithRoleCheck>
          </WithAuthRedirect>
        </AuthProvider>
        <Toaster />
      </Provider>
    </>
  )
}

export default InternalApp
