export const TalentsIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7393 22.9133C21.0445 22.9133 22.9133 21.0445 22.9133 18.7393C22.9133 16.4342 21.0445 14.5654 18.7393 14.5654C16.4342 14.5654 14.5654 16.4342 14.5654 18.7393C14.5654 21.0445 16.4342 22.9133 18.7393 22.9133Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6899 21.6904L24.9999 25.0003"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.6525C1 14.992 1.65963 13.3995 2.83377 12.2254C4.00791 11.0512 5.60038 10.3916 7.26087 10.3916C8.92135 10.3916 10.5138 11.0512 11.688 12.2254C12.8621 13.3995 13.5217 14.992 13.5217 16.6525H1Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2607 8.82609C9.42181 8.82609 11.1737 7.07416 11.1737 4.91304C11.1737 2.75193 9.42181 1 7.2607 1C5.09959 1 3.34766 2.75193 3.34766 4.91304C3.34766 7.07416 5.09959 8.82609 7.2607 8.82609Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1738 8.82609C19.3349 8.82609 21.0868 7.07416 21.0868 4.91304C21.0868 2.75193 19.3349 1 17.1738 1C15.0127 1 13.2607 2.75193 13.2607 4.91304C13.2607 7.07416 15.0127 8.82609 17.1738 8.82609Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7948 11.5441C19.8124 10.848 18.6503 10.4496 17.4474 10.3967C16.2446 10.3438 15.0519 10.6385 14.0122 11.2456"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
