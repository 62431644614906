export const JobsIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 10.75H14.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 14.5H14.5"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 18.25H14.5"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 4.75H17.5C17.8978 4.75 18.2794 4.90804 18.5607 5.18934C18.842 5.47064 19 5.85218 19 6.25V22C19 22.3978 18.842 22.7794 18.5607 23.0607C18.2794 23.342 17.8978 23.5 17.5 23.5H2.5C2.10218 23.5 1.72064 23.342 1.43934 23.0607C1.15804 22.7794 1 22.3978 1 22V6.25C1 5.85218 1.15804 5.47064 1.43934 5.18934C1.72064 4.90804 2.10218 4.75 2.5 4.75H6.25C6.25 3.75544 6.64509 2.80161 7.34835 2.09835C8.05161 1.39509 9.00544 1 10 1C10.9946 1 11.9484 1.39509 12.6517 2.09835C13.3549 2.80161 13.75 3.75544 13.75 4.75V4.75Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4C10.0742 4 10.1467 4.02199 10.2083 4.0632C10.27 4.1044 10.3181 4.16297 10.3465 4.23149C10.3748 4.30002 10.3823 4.37542 10.3678 4.44816C10.3533 4.5209 10.3176 4.58772 10.2652 4.64017C10.2127 4.69261 10.1459 4.72832 10.0732 4.74279C10.0004 4.75726 9.92502 4.74984 9.85649 4.72145C9.78797 4.69307 9.7294 4.64501 9.6882 4.58334C9.64699 4.52167 9.625 4.44917 9.625 4.375C9.625 4.27554 9.66451 4.18016 9.73483 4.10983C9.80516 4.03951 9.90054 4 10 4"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
