import { useEffect, useRef } from 'react'
import { JobForm } from './job-form'

type Props = {
  showSection: (key: string) => void
  hideSection: (key: string) => void
  registerRef: (key: string, ref: HTMLDivElement) => void
}

export function JobStep({ showSection, hideSection, registerRef }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    registerRef('jobStep', ref.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} className="space-y-5 px-3 pb-10">
      <JobForm
        showSection={showSection}
        hideSection={hideSection}
        state="add"
      />
    </div>
  )
}
