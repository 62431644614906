import {
  FormikRichTextEditor,
  FormikSelect,
  FormikSelectField,
  FormikTextField,
} from '@upper/formik'
import { ContactsDocument, SkillsSearchDocument } from '@upper/graphql/internal'
import { useField } from 'formik'
import { ReactNode } from 'react'
import { useClient, useQuery } from 'urql'
import {
  PROJECT_MANAGEMENT_METHODOLOGY,
  TEAM_ENVIRONMENT_OPTIONS,
} from '../../../const/project'
import { FormSectionHeader } from './multi-step-manager'

type Props = {
  namePrefix?: string
  footer?: ReactNode
  companyId: string
  showSection: (key: string) => void
  hideSection: (key: string) => void
}

export function JobProjectForm({
  namePrefix = 'project',
  footer,
  companyId = 'created',
  showSection,
  hideSection,
}: Props) {
  const urqlClient = useClient()

  const [contactsResult] = useQuery({
    query: ContactsDocument,
    variables: {
      filters: {
        companyIds: [companyId],
      },
    },
    pause: !companyId || companyId.startsWith('created'),
    requestPolicy: 'network-only',
  })

  const handleSkillsLoadOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(SkillsSearchDocument, {
          name,
        })
        .toPromise()
      return result.data.searchSkills || []
    } catch (e) {
      console.log(e)
    }
  }

  const [teamEnvironmentField] = useField(`${namePrefix}.teamEnvironment`)
  const [methodologyField] = useField(`${namePrefix}.managementMethodology`)
  const [contactIdField] = useField(`${namePrefix}.contactId`)

  const handleEnter = (key: string) => {
    showSection(key)
  }
  const handleLeave = (key: string) => {
    hideSection(key)
  }

  return (
    <>
      <FormSectionHeader
        id="details"
        onEnter={() => handleEnter('details')}
        onLeave={() => handleLeave('details')}
      >
        Details
      </FormSectionHeader>
      <div className="space-y-6">
        <FormikTextField
          name={`${namePrefix}.name`}
          label="Project name"
          required
        />
        <FormikRichTextEditor
          name={`${namePrefix}.description`}
          label="Project description"
          required
          helpText="(What does the product do, is it in live already, etc)"
        />
        <div className="grid grid-cols-3 gap-2">
          <FormikTextField
            name={`${namePrefix}.phase`}
            label="Product Phase"
            helpText="(e.g. definition, in development, refactoring, maintenance, etc)"
          />
          <FormikTextField
            name={`${namePrefix}.state`}
            label="Existing or new product?"
            helpText="(e.g. existing, new, etc)"
          />
          <FormikTextField
            name={`${namePrefix}.platforms`}
            label="Product platforms"
            helpText="(e.g. iOS, Android, Web, etc)"
          />
        </div>
        {/*  */}
        <FormikSelectField
          isMulti
          isAsync
          name={`${namePrefix}.skills`}
          label="Tech Stack"
          loadOptions={handleSkillsLoadOptions}
          getOptionLabel={(o: any) => o.name}
          getOptionValue={(o: any) => o.id}
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
          }}
          menuPortalTarget={document.body}
          helpText="(e.g. React, Node, Python, etc)"
        />
      </div>
      {/* team */}
      <div className="space-y-6">
        <FormSectionHeader
          onEnter={() => handleEnter('team')}
          onLeave={() => handleLeave('team')}
          id="team"
        >
          Team
        </FormSectionHeader>
        {/*  */}
        <div className="grid grid-cols-2 gap-2">
          <FormikSelectField
            name={`${namePrefix}.teamState`}
            label="Existing or new team?"
            options={['Existing', 'New'].map((o) => ({
              value: o,
              label: o,
            }))}
            asArrayOfValues
          />
          <FormikSelectField
            name={`${namePrefix}.teamSize`}
            label="Team size"
            options={['1-5', '5-10', '10+'].map((o) => ({
              value: o,
              label: o,
            }))}
            asArrayOfValues
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <FormikSelect
            name={`${namePrefix}.teamEnvironment`}
            label="Team environment"
            helpText="(e.g. Startup, Corporate, Highly Political, etc)"
          >
            {TEAM_ENVIRONMENT_OPTIONS.map((o) => (
              <option key={o.value as string} value={o.value}>
                {o.label}
              </option>
            ))}
          </FormikSelect>
          {teamEnvironmentField.value === 'Other' && (
            <FormikTextField
              label="Please specify"
              name={`${namePrefix}.teamEnvironmentOther`}
            />
          )}
        </div>
      </div>
      {/* communication */}
      <div className="space-y-6">
        <FormSectionHeader
          onEnter={() => handleEnter('communication')}
          onLeave={() => handleLeave('communication')}
          id="communication"
        >
          Communication
        </FormSectionHeader>
        {/*  */}
        <div className="grid grid-cols-2 gap-2">
          <FormikTextField
            name={`${namePrefix}.onboardingProcess`}
            label="Onboarding process"
            helpText="(e.g. Who does the onboarding, On-site for the first sprint, Custom agreements, etc)"
          />
          <FormikTextField
            name={`${namePrefix}.communicationModel`}
            label="Communication model"
            helpText="(business hours, overlap, tools)"
          />
        </div>
        {/*  */}
        <div className="grid grid-cols-2 gap-2">
          <FormikSelect
            name={`${namePrefix}.managementMethodology`}
            label="Project management methodology"
          >
            <option value={undefined}></option>
            {PROJECT_MANAGEMENT_METHODOLOGY.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </FormikSelect>
          {methodologyField.value === 'Other' && (
            <FormikTextField
              label="Please specify"
              name={`${namePrefix}.managementMethodologyOther`}
            />
          )}
        </div>
      </div>
      {/* contact */}
      <div className="space-y-6">
        <FormSectionHeader
          onEnter={() => handleEnter('contact')}
          onLeave={() => handleLeave('contact')}
          id="contact"
        >
          Contact
        </FormSectionHeader>
        <FormikSelect
          name={`${namePrefix}.contactId`}
          label="Project Contact"
          required
        >
          <option value="new-contact">New Contact</option>
          <option value="same-as-company">Same as company contact</option>
          {contactsResult.data?.contacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.firstName} {contact.lastName}
            </option>
          ))}
        </FormikSelect>

        {contactIdField.value === 'new-contact' && (
          <>
            <div className="grid grid-cols-2 gap-8">
              <FormikTextField
                name={`${namePrefix}.contact.firstName`}
                label="First name"
                required
              />
              <FormikTextField
                name={`${namePrefix}.contact.lastName`}
                label="Last name"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-8">
              <FormikTextField
                name={`${namePrefix}.contact.email`}
                label="E-mail"
                required
              />
              <FormikTextField
                name={`${namePrefix}.contact.phone`}
                label="Phone (optional)"
              />
            </div>
          </>
        )}
      </div>

      {footer && (
        <footer className="z-20 mt-12 flex w-full gap-2 rounded-lg bg-white">
          {footer}
        </footer>
      )}
    </>
  )
}
