/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JobCommitment,
  JobContractType,
  JobDealSource,
  JobDealType,
  JobLocation,
  JobPriority,
  JobStatus,
} from '@upper/graphql/internal'
import { SchemaOf, array, boolean, mixed, number, object, string } from 'yup'
import { JobInfoInput } from './type'

export const ADD_JOB_COMPANY_INPUT_SCHEMA = object().shape({
  name: string().required('Required'),
  url: string().url('Must be a valid URL').required('Required'),
  description: string().required('Required'),
  hasFreelancers: boolean(),
  hadFreelancers: boolean(),
  legal: string().optional(),
  representativeId: string().optional(),
  legalNotes: string().optional(),
  procurementNotes: string().optional(),
  representative: object({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email('Must be a valid email').required('Required'),
    phone: string(),
    role: string(),
  }),
  em: object().nullable().required('Required'),
  hubspot: object().shape({
    shouldCreate: boolean(),
    selected: object().when('shouldCreate', {
      is: false,
      then: object().nullable().required('Required'),
      otherwise: object().nullable().optional(),
    }),
  }),
})

export const ADD_JOB_PROJECT_INPUT_SCHEMA = object().shape({
  name: string().required('Required'),
  description: string().required('Required'),
  phase: string().nullable(),
  state: string().nullable(),
  platforms: string().nullable(),
  communicationModel: string().nullable(),
  onboardingProcess: string().nullable(),
  managementMethodology: string().nullable(),
  managementMethodologyOther: string().nullable(),
  teamState: string().nullable(),
  teamEnvironment: string().nullable(),
  teamEnvironmentOther: string().nullable(),
  teamSize: string().nullable(),
  skills: array().nullable().of(object<any>()),
  contactId: string(),
  contact: object().when('contactId', {
    is: (v: string) => v === 'new-contact' || v === undefined || v === null,
    then: (schema) =>
      schema.shape({
        firstName: string().required('Required'),
        lastName: string().required('Required'),
        email: string().email('Must be a valid email').required('Required'),
      }),
    otherwise: object().optional(),
  }),
})

export const ADD_JOB_JOB_INPUT_SCHEMA: SchemaOf<
  JobInfoInput & {
    hubspotDeal: { id: string; name: string } | null | undefined
  }
> = object({
  name: string().required('Required'),
  hubspotDealId: string().nullable().optional(),
  hubspotShouldCreate: boolean().optional(),
  hubspotDeal: object<any>()
    .when('hubspotShouldCreate', {
      is: false,
      then: object<any>().nullable().required('Required'),
      otherwise: object<any>().nullable().optional(),
    })
    .nullable(),
  description: string().required('Required'),
  teamSize: number().min(1).required('Required'),
  acceptanceProcess: string().nullable(),
  em: object<any>().nullable().required('Required'),
  emId: string(),
  expertId: string(),
  dealSource: mixed()
    .oneOf(Object.values(JobDealSource))
    .nullable()
    .required('Required'),
  dealType: mixed()
    .oneOf(Object.values(JobDealType))
    .nullable()
    .required('Required'),
  fellowId: string().nullable(),
  hourlyRateBudget: number().nullable(),
  annualSalaryBudget: number().nullable(),
  isPausedSourcing: boolean(),
  languages: array().of(object<any>()).required('Required').min(1, 'Required'),
  contractType: mixed()
    .oneOf(Object.values(JobContractType))
    .nullable()
    .required('Required'),
  position: object<any>().nullable().required('Required'),
  commitment: mixed().oneOf(Object.values(JobCommitment)).required('Required'),
  commitmentInfo: string().nullable().optional(),
  // commitmentInfo: string().when('commitment', {
  //   is: JobCommitment.PartTime,
  //   then: (schema) => schema.required('Required'),
  // }),
  location: mixed().oneOf(Object.values(JobLocation)).required('Required'),
  locationOnSite: string()
    .nullable()
    .when('location', {
      is: JobLocation.Hybrid,
      then: string().min(1, 'Required').required('Required'),
      otherwise: string().nullable().optional(),
    })
    .when('location', {
      is: JobLocation.OnSite,
      then: string().min(1, 'Required').required('Required'),
      otherwise: string().nullable().optional(),
    }),
  locationOnSitePercentage: string()
    .nullable()
    .when('location', {
      is: JobLocation.Hybrid,
      then: string().min(1, 'Required').required('Required'),
      otherwise: string().nullable().optional(),
    }),
  mindset: string(),
  niceToHaveExperience: string().nullable(),
  priority: mixed().oneOf(Object.values(JobPriority)),
  requiredExperience: string().nullable(),
  requiredSkills: array()
    .of(object<any>())
    .required('Required')
    .min(1, 'Required'),
  niceToHaveSkills: array().of(object<any>()),
  roleGoals: string().nullable(),
  length: string(),
  startDate: string().nullable(),
  status: mixed().oneOf(Object.values(JobStatus)),
})

export const ADD_JOB_JOB_HUBSPOT_SCHEMA = object({
  shouldCreate: boolean(),
})

export function getMultiStepValidationSchema() {
  return object().shape({
    company: object().shape({
      selected: object().nullable().optional(),
      add: object().when('state', {
        is: (value: any) => value === 'createNew',
        then: ADD_JOB_COMPANY_INPUT_SCHEMA.required(),
      }),
    }),
    project: object().shape({
      selected: object().nullable().optional(),
      add: object().when('state', {
        is: (value: any) => value === 'createNew',
        then: ADD_JOB_PROJECT_INPUT_SCHEMA.required(),
      }),
      edit: object().when('state', {
        is: (value: any) => value === 'edit',
        then: ADD_JOB_PROJECT_INPUT_SCHEMA.required(),
      }),
    }),
    job: ADD_JOB_JOB_INPUT_SCHEMA,
  })
}