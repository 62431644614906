/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JobCategory,
  JobCommitment,
  JobContractType,
  JobLocation,
  JobPriority,
  JobStatus,
} from '@upper/graphql/internal'
import colors from 'tailwindcss/colors'
import { words } from 'voca'
import { FilterOption } from '../types/filter-option'

export const JOB_HEALTH_COLORS: Record<'red' | 'yellow' | 'green' | 'gray', string> = {
  red: '#F67474',
  yellow: '#FFD130',
  green: '#00CA75',
  gray: colors.slate['400']
}

export const JOB_CONTRACT_OPTIONS: FilterOption[] = Object.keys(
  JobContractType
).map((k) => ({ label: words(JobContractType[k]).join(' '), value: k }))

export const JOB_STATUS_OPTIONS: FilterOption[] = Object.keys(JobStatus).map(
  (k) => ({ label: words(JobStatus[k]).join(' '), value: k })
)

export const JOB_CATEGORY_OPTIONS: FilterOption[] = Object.keys(JobCategory)
  .sort((k) => (k === 'Other' ? 1 : -1))
  .map((k) => ({ label: words(JobCategory[k]).join(' '), value: k }))

export const JOB_PRIORITY_OPTIONS: FilterOption[] = Object.keys(JobPriority).map(
  (k) => ({ label: JobPriority[k], value: k })
)

export const OnHoldOptions: FilterOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const PausedSourcingOptions: FilterOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const COMMITMENT_OPTIONS: FilterOption[] = Object.keys(
  JobCommitment
).map((k) => ({ label: words(k).join(' '), value: k }))

export const CONTRACT_TYPE_OPTIONS: any[] = Object.keys(JobContractType)
  .sort((o) => 1)
  .map((k) => ({ label: words(k).join(' '), value: k }))

export const LOCATION_OPTIONS: FilterOption[] = [
  { label: 'On site', value: JobLocation.OnSite },
  { label: 'Remote', value: JobLocation.Remote },
  { label: 'Hybrid', value: JobLocation.Hybrid },
]

export const JOB_CANCEL_REASON: string[] = [
  'Low budget/day rate',
  'Tech stack too niche',
  'Unrealistic start date',
  'Unrealistic profile expectations',
  'Engagement duration is too short',
  'Language requirements too niche',
  'No traction / candidates available',
  'Unresponsive client',
  'Unrealistic client interview process',
  'Client filled the role internally',
  'Client filled the role via competition',
  'Client no longer needs our services',
  'Other',
]

export const JOB_DURATION: { label: string; value: string }[] = [
  { label: '1 month', value: '1 month' },
  { label: '2 months', value: '2 months' },
  { label: '3 months', value: '3 months' },
  { label: '3-6 months', value: '3-6 months' },
  { label: '6-12 months', value: '6-12 months' },
  { label: '12 months+', value: '12 months+' },
]
