import { useCallback, useState } from 'react'


export type Section = {
  key: string
  visible: boolean
  name: string
  progress: number
}

export const useSectionManager = (initialSections: Section[]) => {
  const [sections, setSections] = useState<Section[]>(initialSections)

  const showSection = useCallback((key: string) => {
    setSections((prev) =>
      prev.map((item) => {
        {
          if (key === item.key) {
            return {
              ...item,
              visible: item.key === key,
            }
          } else {
            return item
          }
        }
      })
    )
  }, [])

  const hideSection = useCallback((key: string) => {
    setSections((prev) =>
      prev.map((item) => {
        {
          if (key === item.key) {
            return {
              ...item,
              visible: item.key !== key,
            }
          } else {
            return item
          }
        }
      })
    )
  }, [])

  return { sections, showSection, hideSection }
}