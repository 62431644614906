import { Button } from '@upper/sapphire/ui'
import { serializeFilters } from '@upper/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { ArrowRightIcon, CheckIcon } from 'lucide-react'
import Link from 'next/link'
import { stringify } from 'querystring'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { SnapshotFrom } from 'xstate'
import multiStepMainForm from '../../../state-machine/multi-step-main-form'
import { StepBadge } from './step-badge'

const STEP_BADGE_MAP = {
  selectExisting: 'Linked',
  createNew: 'Created',
  edit: 'Updated',
}

const STEP_BADGE_COLOR_MAP = {
  selectExisting: 'bg-blue-50',
  createNew: 'bg-green-50',
  edit: 'bg-yellow-50',
}

const STEP_ICON_COLOR_MAP = {
  selectExisting: 'text-blue-600',
  createNew: 'text-green-600',
  edit: 'text-yellow-600',
}

type SuccessStepProps = {
  state: { context: SnapshotFrom<typeof multiStepMainForm>['context'] }
  onReset: () => void
  onCancel: () => void
}

const SuccessStep = React.memo(
  ({ state, onReset, onCancel }: SuccessStepProps) => {
    return (
      <motion.div className="absolute inset-0 z-10 bg-green-50 py-20 overflow-auto">
        <div className="mx-auto max-w-md space-y-20">
          <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full border border-green-800/10 bg-green-100">
            <CheckIcon className="h-12 w-12 text-green-600" />
          </div>
          <div
            className={twMerge('mx-auto max-w-md space-y-5 rounded-2xl p-1')}
          >
            {state.context.state === 'add' && (
              <SuccessCardStep
                label="Company"
                step={
                  state.context.companyStep as 'selectExisting' | 'createNew'
                }
                name={state.context.successCompany?.name}
                href={`/?${stringify({
                  filters: serializeFilters({
                    companyIds: [state.context.successCompany?.id],
                  }),
                })}`}
              />
            )}
            <SuccessCardStep
              label="Project"
              step={
                state.context.projectStep as
                  | 'selectExisting'
                  | 'createNew'
                  | 'edit'
              }
              name={state.context.successProject?.name}
              href={`/?${stringify({
                filters: serializeFilters({
                  companyIds: [state.context.successCompany?.id],
                  projectIds: [state.context.successProject?.id],
                }),
              })}`}
            />
            <SuccessCardStep
              label="Job"
              step={
                state.context.jobStep as 'createNew' | 'selectExisting' | 'edit'
              }
              name={state.context.successJob?.name}
              href={`/jobs/${state.context.successJob?.id}`}
            />
            {state.context.state === 'add' && (
              <Button variant="primary" onClick={onReset} className="w-full">
                Add another job
              </Button>
            )}
            <Button variant="ghost" onClick={onCancel} className="w-full">
              Close
            </Button>
          </div>
        </div>
      </motion.div>
    )
  }
)

SuccessStep.displayName = 'SuccessStep'

export { SuccessStep }

type SuccessStepCardProps = {
  step: 'createNew' | 'selectExisting' | 'edit'
  name: string
  href: string
  label: string
}
function SuccessCardStep({ step, name, href, label }: SuccessStepCardProps) {
  return (
    <motion.div
      className={twMerge(
        'shadow-outer-stroke flex flex-col gap-2 rounded-xl bg-white p-2 leading-none'
      )}
    >
      <p className="-mr-1 flex items-center gap-1 px-1">
        {label}
        <AnimatePresence>
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <CheckIcon
              className={twMerge('h-4 w-4', STEP_ICON_COLOR_MAP[step])}
              strokeWidth={3}
            />
          </motion.span>
        </AnimatePresence>
        <span className="ml-auto inline-flex">
          <StepBadge step={step as 'selectExisting' | 'createNew' | 'edit'}>
            {STEP_BADGE_MAP[step]}
          </StepBadge>
        </span>
      </p>
      <Link
        href={href}
        className={twMerge(
          'transition-all duration-300',
          'group/link -mx-1 -mb-1 inline-flex items-center justify-between gap-1 rounded-lg py-1 px-2 text-sm leading-none hover:bg-white',
          'bg-slate-100'
        )}
        target="_blank"
      >
        {name}
        <ArrowRightIcon className="h-4 w-4 transition-all duration-300 group-hover/link:translate-x-1" />
      </Link>
    </motion.div>
  )
}
