import { CompaniesDocument, CompaniesQuery } from '@upper/graphql/internal'
import { Text } from '@upper/ui'
import { CheckIcon, XIcon } from 'lucide-react'
import { twMerge } from 'tailwind-merge'
import { useClient } from 'urql'
import { CardsSelect } from '../../../components/cards-select'

type CompanySelectProps = {
  selectedCompany: CompaniesQuery['companies'][0] | null
  setSelectedCompany: (company: CompaniesQuery['companies'][0]) => void
}

export function CompanySelect({
  selectedCompany,
  setSelectedCompany,
}: CompanySelectProps) {
  const client = useClient()

  const loadCompanies = async (search: string) => {
    const { data, error } = await client.query(
      CompaniesDocument,
      {
        filters: { name: search },
      },
      { requestPolicy: 'network-only' }
    )
    return data.companies
  }

  return (
    <div className="mx-auto max-w-md space-y-2">
      {selectedCompany && (
        <ListButton
          company={selectedCompany}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          isClearable
        />
      )}
      {!selectedCompany && (
        <CardsSelect<CompaniesQuery['companies'][0]>
          options={[]}
          renderOption={(option) => (
            <ListButton
              company={option}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
          )}
          loadOptions={loadCompanies}
        />
      )}
    </div>
  )
}

type ListButtonProps = {
  company: CompaniesQuery['companies'][0]
  selectedCompany: CompaniesQuery['companies'][0] | null
  setSelectedCompany: (company: CompaniesQuery['companies'][0]) => void
  isClearable?: boolean
}
const ListButton = ({
  company,
  selectedCompany,
  setSelectedCompany,
  isClearable,
}: ListButtonProps) => {
  return (
    <button
      className={twMerge(
        'flex w-full items-center gap-2 rounded-xl bg-slate-50 p-2 text-left transition-all hover:bg-slate-100 hover:text-slate-800',
        selectedCompany?.id === company.id
          ? 'bg-white text-slate-800 hover:bg-slate-50'
          : ''
      )}
      onClick={() => {
        if (selectedCompany?.id === company.id) {
          setSelectedCompany(null)
        } else {
          setSelectedCompany(company)
        }
      }}
      type="button"
    >
      <Text variant="title">{company.name}</Text>
      {company.hubspotId && (
        <div className="ml-auto flex items-center gap-1 rounded-md bg-yellow-300 px-2 py-1 text-xs text-yellow-800">
          <CheckIcon className="h-3 w-3" /> Hubspot
        </div>
      )}
      {isClearable && <XIcon className="h-3 w-3" />}
    </button>
  )
}
