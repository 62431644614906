import * as Dialog from '@radix-ui/react-dialog'
import { useMounted } from '@upper/hooks'
import { CrossIcon } from '@upper/icons'
import { classNames } from '@upper/ui'
import { CSSProperties, PropsWithChildren, useEffect } from 'react'

type Sizes = 'normal' | 'large' | 'double'
// eslint-disable-next-line @typescript-eslint/ban-types
const classNameForSize: Record<Sizes, string | undefined> = {
  normal: '',
  large: 'grid',
  double: 'grid grid-cols-2',
}
const styleForSize: Record<Sizes, Partial<CSSProperties>> = {
  normal: { width: 470 },
  large: { width: 980, gridTemplateColumns: '320px 1fr' },
  double: { width: 940 },
}
type Props = {
  className?: string
  size?: Sizes
  onClose?: () => void
  contentProps?: Dialog.DialogContentProps
  showExteriorCloseButton?: boolean
}
export const DrawerBase = ({
  children,
  className,
  size = 'normal',
  contentProps,
  showExteriorCloseButton = true,
  onClose,
}: PropsWithChildren<Props>) => {
  const mounted = useMounted()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [mounted])
  return (
    <Dialog.Root
      defaultOpen
      onOpenChange={(v) => {
        if (!v) onClose?.()
      }}
      modal={false}
    >
      <Dialog.Portal>
        <div className="fixed z-50 inset-0 bg-gray-dark/10" />
        <Dialog.Content
          className={classNames('fixed z-50 top-6 bottom-6 right-2')}
          {...contentProps}
        >
          {showExteriorCloseButton && (
            <Dialog.Close className="absolute top-6 bg-gray-light p-2 -left-8 text-gray-dark rounded-l-md">
              <CrossIcon />
            </Dialog.Close>
          )}
          <div
            className={classNames(
              'rounded-lg shadow-lg bg-white h-full overflow-auto',
              classNameForSize[size],
              className
            )}
            style={styleForSize[size]}
          >
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
