import { classNames } from '@upper/ui'
import { PropsWithChildren, ReactNode } from 'react'

export const SideFormSectionRow = ({
  children,
  className,
  extra,
}: PropsWithChildren<{ className?: string; extra?: ReactNode }>) => {
  return (
    <section className={classNames('', className)}>
      <div className="grid grid-cols-4 gap-0">
        <div className="col-span-4">{children}</div>
        <div className="col-span-4">{extra}</div>
      </div>
    </section>
  )
}

const Title = ({
  children,
  extra,
  className,
}: PropsWithChildren<{ className?: string; extra?: ReactNode }>) => {
  return (
    <h4
      className={classNames(
        'rounded-lg bg-slate-200 px-5 py-2 font-medium text-slate-600',
        className
      )}
    >
      {children}
      {extra}
    </h4>
  )
}

SideFormSectionRow.Title = Title
