import {
  createElement,
  CSSProperties,
  PropsWithChildren,
  ReactHTML,
} from 'react'
import { useNavigation } from '../hooks/use-navigation'
import { cn } from '@upper/sapphire/ui'

type Props = {
  withoutPadding?: boolean
  as?: keyof ReactHTML
  className?: string
  style?: CSSProperties
}

export const MainNavigationLayer = ({
  as = 'div',
  withoutPadding,
  className,
  ...props
}: PropsWithChildren<Props>) => {
  const { paddedSize, margin } = useNavigation()

  const styles: CSSProperties = props.style || {}
  styles.transition = 'all 300ms ease'
  styles.paddingLeft = withoutPadding
    ? `${paddedSize}px`
    : `${paddedSize + margin}px`

  return createElement(as, {
    ...props,
    style: {
      ...styles,
    },
    className: cn('print:!p-0', className),
  })
}
