export enum ModalAction {
  ADD_TALENT = 'add-talent',
  ADD_JOB = 'add-job',
  EDIT_JOB = 'edit-job',
  MANAGE_JOB = 'manage-job',
}

export type EditJobModalPayload = {
  id: string
  name: string
  projectId: string
  projectName: string
  companyId: string
}