import { SearchIcon, XIcon } from 'lucide-react'
import { ReactNode, useCallback, useState } from 'react'
import { Button, Input, SearchField } from 'react-aria-components'
import { useDebounce } from 'rooks'
import { twMerge } from 'tailwind-merge'

type CardsSelectProps<T extends { id?: string }> = {
  // isLoading: boolean
  options: T[]
  renderOption?: (option: T) => ReactNode
  loadOptions?: (search: string) => Promise<T[]>
}
export function CardsSelect<T extends { id?: string }>({
  // isLoading,
  options,
  renderOption,
  loadOptions,
}: CardsSelectProps<T>) {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const [localOptions, setLocalOptions] = useState<T[]>(options)
  const [isLoading, setIsLoading] = useState(false)

  const fetchOptions = useCallback(
    async (value: string) => {
      if (loadOptions) {
        setIsLoading(true)
        try {
          const results = await loadOptions(value)
          setLocalOptions(results)
        } catch (error) {
          console.error('Error fetching options:', error)
          setLocalOptions([])
        } finally {
          setIsLoading(false)
        }
      } else {
        const filteredOptions = options.filter((option) =>
          Object.values(option).some((value) =>
            String(value).toLowerCase().includes(value.toLowerCase())
          )
        )
        setLocalOptions(filteredOptions)
      }
    },
    [loadOptions, options]
  )
  const searchDebounced = useDebounce(fetchOptions, 300)

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      if (e.target.value?.length > 2) {
        searchDebounced(e.target.value)
      }
    },
    [searchDebounced]
  )

  return (
    <>
      <SearchField
        className={twMerge(
          'inline-flex w-full items-center rounded-lg border border-slate-200 bg-white text-sm',
          'focus-within:border-blue-700 focus-within:ring-1 focus-within:ring-blue-700'
        )}
        onClear={() => setSearch('')}
      >
        {({ state }) => (
          <>
            <SearchIcon className="ml-2 w-5 flex-none text-slate-400" />
            <Input
              value={search}
              onChange={handleSearch}
              placeholder="Search"
              className={
                'w-full rounded-lg border-none px-2 text-slate-800 focus:border-none focus:outline-none focus:ring-0'
              }
            />
            {state.value !== '' && (
              <Button className={'flex-none px-2 text-slate-400'}>
                <XIcon className="w-4" />
              </Button>
            )}
          </>
        )}
      </SearchField>
      {search && (
        <div
          className={twMerge(
            'overflow-auto',
            'flex min-w-[300px] flex-col gap-1 rounded-2xl bg-white p-1'
          )}
          style={{
            height:
              localOptions.length === 0
                ? '32px'
                : Math.min(
                    localOptions.length * 40 + (localOptions.length + 1) * 4,
                    120
                  ),
          }}
        >
          {isLoading ? (
            <div className="text-center text-sm">Loading...</div>
          ) : localOptions.length === 0 ? (
            <div className="text-center text-sm">No results found</div>
          ) : (
            localOptions.map((option) => renderOption?.(option))
          )}
        </div>
      )}
    </>
  )
}
