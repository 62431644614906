import { motion } from 'framer-motion'
import { CheckCircleIcon, Loader2Icon, LoaderCircleIcon } from 'lucide-react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type LoadingStepProps = {
  projectName: string
  jobName: string
  projectLoading: boolean
  jobLoading: boolean
}

const LoadingStep = React.memo(
  ({ projectName, jobName, projectLoading, jobLoading }: LoadingStepProps) => {
    return (
      <motion.div
        className="absolute inset-0 z-10 bg-slate-100 py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="mx-auto max-w-md space-y-20">
          <div className="shadow-inner-stroke mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-yellow-50">
            <LoaderCircleIcon className="h-12 w-12 animate-spin text-yellow-600" />
          </div>
          <div
            className={twMerge('mx-auto max-w-md space-y-5 rounded-2xl p-1')}
          >
            <LoadingCard
              label="Project"
              name={projectName}
              isLoading={projectLoading}
            />
            <LoadingCard label="Job" name={jobName} isLoading={jobLoading} />
          </div>
        </div>
      </motion.div>
    )
  }
)

LoadingStep.displayName = 'LoadingStep'

export { LoadingStep }

type LoadingCardProps = {
  name: string
  label: string
  isLoading: boolean
}
function LoadingCard({ name, label, isLoading }: LoadingCardProps) {
  return (
    <motion.div
      className={twMerge(
        'shadow-outer-stroke flex flex-col gap-1 rounded-xl bg-white p-2 leading-none'
      )}
    >
      <p className="flex items-center gap-1 px-1 leading-none">{label}</p>
      <p
        className={twMerge(
          'leading-none transition-all duration-300',
          'group/link -mx-1 -mb-1 inline-flex items-center justify-between gap-1 rounded-lg py-1 px-2 text-sm leading-none hover:bg-white'
        )}
      >
        {name}
        {isLoading ? (
          <Loader2Icon className="h-4 w-4 animate-spin text-yellow-600" />
        ) : (
          <CheckCircleIcon className="h-4 w-4 text-green-600" />
        )}
      </p>
    </motion.div>
  )
}
