import { PropsWithChildren } from 'react'
import { MainNavigation } from '../../components/main-navigation'
import { classNames } from '@upper/ui'
import { NavigationProvider } from '@upper/sapphire/ui'

// eslint-disable-next-line @typescript-eslint/ban-types
type MainLayoutProps = { className?: string }

export function MainLayout({
  children,
  className,
}: PropsWithChildren<MainLayoutProps>) {
  return (
    <>
      <NavigationProvider>
        <div
          className="fixed bottom-3 left-3 top-3 z-50 print:hidden"
          style={{ minHeight: 560 }}
        >
          <MainNavigation />
        </div>
        <div
          className={classNames('h-screen w-screen overflow-auto', className)}
        >
          {children}
        </div>
      </NavigationProvider>
    </>
  )
}
