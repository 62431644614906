export const formatNumber = (number: number) => {
  if (!number) return
  return Intl.NumberFormat('en-DE', {
    maximumFractionDigits: 2,
  }).format(number)
}

export function formatNumberToShort(num: number): string {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K'
  }
  return num.toFixed(2)
}
