import { Button } from '@upper/sapphire/ui'
import { Text } from '@upper/ui'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { SnapshotFrom } from 'xstate'
import multiStepMainForm from '../../../state-machine/multi-step-main-form'
import { StepButton } from './step-button'

const COMPANY_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  {
    name: 'Representative',
    progress: 0,
    key: 'representative',
    visible: false,
  },
  { name: 'More details', progress: 0, key: 'more-details', visible: false },
  { name: 'Hubspot', progress: 0, key: 'hubspot', visible: false },
]

const PROJECT_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  { name: 'Team', progress: 0, key: 'team', visible: false },
  { name: 'Communication', progress: 0, key: 'communication', visible: false },
  { name: 'Contact', progress: 0, key: 'contact', visible: false },
]

const JOB_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  { name: 'Experience', progress: 0, key: 'experience', visible: false },
  { name: 'Freelancer', progress: 0, key: 'freelancer', visible: false },
  { name: 'Engagement', progress: 0, key: 'engagement', visible: false },
  { name: 'Budget', progress: 0, key: 'budget', visible: false },
  { name: 'More details', progress: 0, key: 'more-details', visible: false },
  { name: 'Hubspot', progress: 0, key: 'hubspot', visible: false },
]

type SideNavProps = {
  state: SnapshotFrom<typeof multiStepMainForm>
  send: (event: any) => void
  companyName: string
  projectName: string
  jobName: string
  isSubmitting: boolean
  isCompanyValid: boolean
  isProjectValid: boolean
  isJobValid: boolean
  companySections: typeof COMPANY_FORM_SECTIONS
  projectSections: typeof PROJECT_FORM_SECTIONS
  jobSections: typeof JOB_FORM_SECTIONS
  refs: Record<string, HTMLElement>
  onCancel: () => void
  onSave: () => void
}
export function SideNav({
  state,
  send,
  companyName,
  projectName,
  jobName,
  isSubmitting,
  isCompanyValid,
  isProjectValid,
  isJobValid,
  companySections,
  projectSections,
  jobSections,
  refs,
  onCancel,
  onSave,
}: SideNavProps) {
  return (
    <motion.nav
      initial={{ opacity: 0, width: 0 }}
      animate={{
        opacity: 1,
        width: '300px',
        transition: { delay: 0.2 },
      }}
      exit={{ opacity: 0, width: 0 }}
      className="sticky top-2 z-[5] flex flex-none flex-col gap-0 overflow-auto border-r border-slate-200 bg-slate-100 p-2"
    >
      <header className="px-3 pt-1 pb-2">
        <Text>{state.context.state === 'add' ? 'Add' : 'Edit'} Job</Text>
      </header>
      <motion.div className="flex flex-col items-center gap-2 overflow-visible">
        {state.context.state === 'add' && (
          <StepButton
            active={state.matches('companyStep')}
            name="Company"
            label={companyName}
            onClick={() => send({ type: 'COMPANY_STEP' })}
            step={
              (state.context.companyStep as 'selectExisting' | 'createNew') ??
              null
            }
            isEnabled={!isSubmitting}
            isValid={isCompanyValid}
          >
            <motion.div
              className="w-full space-y-1 overflow-hidden px-1 pb-3 text-sm"
              initial={{ height: 0 }}
              animate={{
                height:
                  companySections.length * 28 + companySections.length * 4,
              }}
              exit={{ height: 0 }}
              transition={{ duration: 0.3 }}
              layout
            >
              {companySections.map((item) => (
                <button
                  key={item.key}
                  className={twMerge(
                    'block w-full rounded-md px-2 py-1',
                    item.visible && 'bg-black/5 text-slate-700'
                  )}
                  onClick={() =>
                    refs['companyStep']
                      .querySelector(`#${item.key}`)
                      ?.scrollIntoView({
                        behavior: 'smooth',
                      })
                  }
                >
                  {item.name}
                </button>
              ))}
            </motion.div>
          </StepButton>
        )}
        <StepButton
          active={state.matches('projectStep')}
          name="Project"
          label={projectName}
          onClick={() => send({ type: 'PROJECT_STEP' })}
          step={
            (state.context.projectStep as 'selectExisting' | 'createNew') ??
            null
          }
          isEnabled={isCompanyValid && !isSubmitting}
          isValid={isProjectValid}
        >
          <motion.div
            className="w-full space-y-1 overflow-hidden px-1 pb-3 text-sm"
            initial={{ height: 0 }}
            animate={{
              height: projectSections.length * 28 + projectSections.length * 4,
            }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            layout
          >
            {projectSections.map((item) => (
              <button
                key={item.key}
                className={twMerge(
                  'block w-full rounded-md px-2 py-1',
                  item.visible && 'bg-black/5 text-slate-700'
                )}
                onClick={() =>
                  refs['projectStep']
                    .querySelector(`#${item.key}`)
                    ?.scrollIntoView({
                      behavior: 'smooth',
                    })
                }
              >
                {item.name}
              </button>
            ))}
          </motion.div>
        </StepButton>
        <StepButton
          active={state.matches('jobStep')}
          name="Job"
          onClick={() => send({ type: 'JOB_STEP' })}
          step={state.context.jobStep}
          label={jobName}
          isEnabled={
            state.context.state === 'edit' || (isProjectValid && !isSubmitting)
          }
          isValid={isJobValid}
        >
          <motion.div
            className="w-full space-y-1 overflow-hidden px-1 pb-3 text-sm"
            initial={{ height: 0 }}
            animate={{
              height: jobSections.length * 28 + jobSections.length * 4,
            }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            layout
          >
            {jobSections.map((item) => (
              <button
                key={item.key}
                className={twMerge(
                  'block w-full rounded-md px-2 py-1',
                  item.visible && 'bg-black/5 text-slate-700'
                )}
                onClick={() =>
                  refs['jobStep']
                    .querySelector(`#${item.key}`)
                    ?.scrollIntoView({
                      behavior: 'smooth',
                    })
                }
              >
                {item.name}
              </button>
            ))}
          </motion.div>
        </StepButton>
      </motion.div>
      <footer className="sticky -bottom-2 z-10 -mx-2 -mb-2 mt-auto flex justify-between gap-3 bg-slate-100 p-2">
        <Button onClick={onCancel} variant="ghost" className="bg-slate-100">
          Cancel
        </Button>
        {!state.matches('success') && (
          <Button
            variant="primary"
            disabled={!isCompanyValid || !isProjectValid || !isJobValid}
            loading={isSubmitting}
            onClick={onSave}
          >
            Save
          </Button>
        )}
      </footer>
    </motion.nav>
  )
}
