export const averageFromNumbers = (
  numbers: number[],
  rounded = true
): number => {
  const value =
    numbers?.filter((v) => !!v)?.reduce((acc, v) => (acc += v), 0) /
    numbers?.length
  return rounded ? Math.round(value) : value
}

export const fullPercentageForValue = (value: number): number => {
  return value ? Math.round(value * 100) : 0
}
