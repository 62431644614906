import { Button, Tabs, TabsList, TabsTrigger } from '@upper/sapphire/ui'
import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { snakeCase, upperCase } from 'voca'
import { ActorRefFrom, SnapshotFrom } from 'xstate'
import multiStepMainForm from '../../../state-machine/multi-step-main-form'
import { JobCompanyForm } from './company-form'
import { CompanySelect } from './company-select'

type CompanyStepProps = {
  state: {
    matches: (value: string) => boolean
    context: SnapshotFrom<typeof multiStepMainForm>['context']
  }
  send: ActorRefFrom<typeof multiStepMainForm>['send']
  canContinue: boolean
  registerRef: (key: string, ref: HTMLDivElement | null) => void
  showSection: (key: string) => void
  hideSection: (key: string) => void
}
export function CompanyStep({
  state,
  send,
  canContinue,
  registerRef,
  showSection,
  hideSection,
}: CompanyStepProps) {
  const { values, setFieldValue } = useFormikContext<any>()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    registerRef('companyStep', ref.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={ref}
      className={twMerge(
        'pb-20',
        state.matches('companyStep.createNew') && 'mt-0 mb-0 pb-10'
      )}
    >
      <header
        className={twMerge(
          'sticky top-0 z-10 mt-60 mb-5 w-full space-y-3 rounded-xl p-1 transition-all duration-300',
          state.matches('companyStep.createNew') && 'mt-0 mb-0 p-0'
        )}
      >
        {!state.matches('companyStep.createNew') && (
          <>
            <h3 className="text-center text-2xl font-semibold text-blue-700">
              Select or Create Company
            </h3>
            <p className="mx-auto max-w-md text-center text-sm text-slate-500">
              Choose an existing company or create a new one to associate with
              your project and job.
            </p>
          </>
        )}
      </header>
      <Tabs
        value={state.context.companyStep}
        onValueChange={(value) => {
          send({
            type: upperCase(snakeCase(value)),
          })
        }}
        className="sticky top-0 z-[5] mx-auto mb-5 w-full max-w-md"
      >
        <TabsList className="shadow-outer-stroke w-full gap-1 bg-white">
          <TabsTrigger
            value="selectExisting"
            className="flex w-full items-center justify-center gap-2 bg-slate-50 !shadow-none hover:bg-white active:bg-blue-50 active:text-blue-700"
          >
            {/* <FolderIcon className="h-4 w-4" /> */}
            Select Existing Company
          </TabsTrigger>
          <TabsTrigger
            value="createNew"
            className="flex w-full items-center justify-center gap-2 bg-slate-50 !shadow-none hover:bg-white active:bg-green-50 active:text-green-700"
          >
            {/* <PlusCircleIcon className="h-4 w-4" /> */}
            Create New Company
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {state.matches('companyStep.selectExisting') && (
        <CompanySelect
          selectedCompany={values.company.selected}
          setSelectedCompany={(company) =>
            setFieldValue('company.selected', company)
          }
        />
      )}
      {state.matches('companyStep.createNew') && (
        <div className="space-y-5 px-3">
          <JobCompanyForm
            prefix="company.add"
            handleEnter={showSection}
            handleLeave={hideSection}
          />
        </div>
      )}
      <Button
        onClick={() => send({ type: 'NEXT' })}
        className="mx-auto mt-5 flex w-40"
        disabled={!canContinue}
      >{`Continue to Project`}</Button>
    </div>
  )
}
