import { TalentsWithEngagementsDocument } from '@upper/graphql/internal'
import { formatDate, formatName } from '@upper/utils'
import Link from 'next/link'
import { useQuery } from 'urql'

type AddUserCheckProps = {
  email?: string
}
export function AddTalentCheck({ email }: AddUserCheckProps) {
  const [talentResult] = useQuery({
    query: TalentsWithEngagementsDocument,
    variables: { filters: { email } },
  })

  const talent = talentResult.data?.talents && talentResult.data?.talents[0]

  const canShowCard = !!email

  return (
    canShowCard && (
      <div className="mt-6">
        {talent ? (
          <div className="bg-yellow rounded-md p-5">
            <p className="text-sm">
              A user with this first and last name already exists.
            </p>
            <div className="mt-5 rounded bg-white p-5">
              <header className="">
                <h6 className="text-gray-dark">{`${formatName(talent)}`}</h6>
                <p className="text-gray leading-none">
                  {talent.primaryPosition?.name}
                </p>
              </header>
              <div className="text-blue mt-6 flex gap-2 text-sm">
                <a href={`mailto:${talent.email}`} className="underline">
                  {talent.email}
                </a>
                {talent.onlineLink && (
                  <span className="text-gray block no-underline">{' | '}</span>
                )}
                {talent.onlineLink && (
                  <a href={talent.onlineLink} className="underline">
                    online presence
                  </a>
                )}
              </div>
              <div className="text-gray mt-6 pr-20 text-sm">
                {/* added date */}
                <div className="grid grid-cols-2">
                  <span>Added:</span>
                  <span className="text-gray-dark">
                    {formatDate(talent.createdAt)}
                  </span>
                </div>
                {/* sourced by */}
                {talent.sourcedBy && (
                  <div className="grid grid-cols-2">
                    <span>Sourced:</span>
                    <span className="text-gray-dark flex gap-2">
                      {`${formatName(talent.sourcedBy)}`}{' '}
                      <span className="text-gray capitalize">
                        ({talent.source})
                      </span>
                    </span>
                  </div>
                )}
                {/* jobs */}
                <div className="grid grid-cols-2">
                  <span>Jobs:</span>
                  <div className="flex flex-wrap gap-2">
                    {talent.engagements.map((e) => (
                      <Link
                        key={e.id}
                        href={`/jobs/${e.job.id}`}
                        className="text-blue whitespace-nowrap underline"
                      >
                        {e.job.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-green rounded-md p-5">
            <p className="text-sm leading-tight text-white">
              This user <b>is not</b> in our database.
              <br /> Safe to continue {':)'}
            </p>
          </div>
        )}
      </div>
    )
  )
}
