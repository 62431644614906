import {
  FormikCreatableMultiComboBox,
  FormikSelect,
  FormikSelectField,
  FormikSubmitButton,
  FormikTextField,
} from '@upper/formik'
import {
  AgenciesForListDocument,
  CountriesDocument,
  CreateSkillDocument,
  CreateSkillMutation,
  EngagementRevisionInput,
  FreelancerCreateEngagementDocument,
  PositionsDocument,
  SkillsDocument,
  SkillsSearchDocument,
  TalentCreateDocument,
  TalentInput,
  TalentProfileCreateDocument,
  UsersDocument,
} from '@upper/graphql/internal'
import { useModalsManager } from '@upper/hooks'
import { PlusIcon, TrashIcon } from '@upper/icons'
import {
  Button,
  ButtonSecondary,
  CreatableSkillInfo,
  CreatableSkillPrompt,
  CreatableSkillTooltip,
  DateField,
  SelectField,
} from '@upper/ui'
import {
  TALENT_SOURCE_INFO,
  formatName,
  omitEmpty,
  phone,
  pick,
} from '@upper/utils'
import { FieldArray, Form, Formik } from 'formik'
import omitDeep from 'omit-deep'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { components } from 'react-select'
import { useClient, useMutation, useQuery } from 'urql'
import { isEmpty } from 'voca'
import * as Yup from 'yup'
import { DrawerBase } from '../../../components/drawer-base'
import { ENGAGEMENT_STATUS_GROUPED_OPTIONS } from '../../../const/engagement'
import { ModalAction } from '../../../const/modals'
import {
  AddFreelancerStatusSelectOptions,
  TALENT_AVAILABILITY_OPTIONS,
  TALENT_STATUS_OPTIONS,
} from '../../../const/talent'
import { JobFinder } from '../../../fragments/job-finder'
import { Timezones } from '../../../types/date'
import { AddTalentCheck } from '../add-talent-check'

type AddFreelancerForm = Pick<
  TalentInput,
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'email'
  | 'country'
  | 'onlineLink'
  | 'primaryPosition'
  | 'talentSkills'
  | 'status'
  | 'source'
  | 'hourlyRate'
  | 'timezone'
  | 'city'
  | 'agency'
  | 'sourcedBy'
  | 'linkedinLink'
  | 'links'
>

const initialFormValue: AddFreelancerForm = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  country: null,
  onlineLink: '',
  primaryPosition: null,
  talentSkills: [],
  status: null,
  source: null,
  hourlyRate: 0,
  timezone: '',
  city: '',
  agency: null,
  sourcedBy: null,
  linkedinLink: '',
  links: { other: [], github: '' },
}

function getOptionValue(option: any) {
  return option?.skill?.['id']
}
function getOptionLabel(option: any) {
  return option?.skill?.['name']
}
function getNewCreateOptionData(inputValue: any) {
  return {
    skill: {
      id: `create-${inputValue}`,
      name: `Add '${inputValue}' skill`,
      value: inputValue,
    },
    years: null,
  }
}
function getNewOptionData(inputValue: any) {
  return {
    skill: {
      id: `create-${inputValue}`,
      name: inputValue,
    },
    years: null,
  }
}

export const AddFreelancerDrawer = () => {
  const { visible, hide } = useModalsManager(ModalAction.ADD_TALENT)

  const urqlClient = useClient()
  const [jobId, setJobId] = useState<string>()
  const [revision, setRevision] = useState<Partial<EngagementRevisionInput>>({})

  const [positionsSelectResult] = useQuery({
    query: PositionsDocument,
    pause: !visible,
    variables: undefined,
  })
  const [countriesResult] = useQuery({
    query: CountriesDocument,
    pause: !visible,
    variables: undefined,
  })
  const [skillsResult] = useQuery({
    query: SkillsDocument,
    pause: !visible,
    variables: undefined,
  })

  const [createSkill, setCreateSkill] = useState<string>()
  const [, createTalent] = useMutation(TalentCreateDocument)

  const [talentAvailability, setTalentAvailability] = useState('')

  const countryOptions = countriesResult.data?.countries ?? []
  const primaryPositionOptions = positionsSelectResult.data?.positions ?? []
  const skillsOptions =
    skillsResult.data?.skills?.map((s) => ({ skill: s })) ?? []

  const handleSkillsLoadOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(SkillsSearchDocument, {
          name,
        })
        .toPromise()
      return result.data?.searchSkills?.map((s) => ({ skill: s })) || []
    } catch (e) {
      console.log(e)
    }
  }

  const handleCountriesLoadOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(CountriesDocument, {
          filters: { name },
        })
        .toPromise()
      return result.data.countries || []
    } catch (e) {
      console.log(e)
    }
  }

  const handleAgenciesLoadOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(AgenciesForListDocument, {
          filters: { name },
        })
        .toPromise()
      return result.data.agencies || []
    } catch (e) {
      console.log(e)
    }
  }

  const handleSourcedByOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(UsersDocument, {
          filters: { name },
        })
        .toPromise()
      return (
        result.data.users?.map((u) => pick(u, 'id', 'firstName', 'lastName')) ||
        []
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handlePrimaryPositionsLoadOptions = async (name: string) => {
    try {
      const result = await urqlClient
        .query(PositionsDocument, { filters: { name } })
        .toPromise()
      return result.data.positions || []
    } catch (e) {
      console.log(e)
    }
  }

  const handleRevisionChange = useCallback(
    (data: Partial<EngagementRevisionInput>) => {
      setRevision((r) => ({ ...r, ...data }))
    },
    []
  )

  useEffect(() => {
    if (!visible) {
      setTalentAvailability(undefined)
      setJobId(undefined)
    }
  }, [visible])

  return (
    <>
      {visible && (
        <DrawerBase>
          <Formik<AddFreelancerForm>
            initialValues={initialFormValue}
            onSubmit={async (
              data,
              { setSubmitting, resetForm, setErrors, validateForm }
            ) => {
              setSubmitting(true)
              validateForm(data)
              try {
                const createSkills = [
                  ...(data.talentSkills
                    ?.filter((o: any) => o.skill?.id?.includes('create'))
                    ?.map((s) => ({ name: s.skill?.name })) ?? []),
                ]
                let createdSkills: CreateSkillMutation['createSkill'][] = []
                if (createSkills.length > 0) {
                  await Promise.all(
                    createSkills.map((s) =>
                      urqlClient
                        .mutation(CreateSkillDocument, {
                          data: s,
                        })
                        .toPromise()
                    )
                  ).then((result) => {
                    createdSkills = result.map((r) => ({
                      id: r.data?.createSkill?.id,
                      name: r.data?.createSkill?.name,
                    }))
                  })
                }
                let links = data.links
                if (links && links.other && typeof links.other === 'string') {
                  links.other = (links.other as string).split(',')
                }
                links = omitEmpty(links)

                const talent = await createTalent({
                  data: {
                    ...omitDeep(omitEmpty(data), ['__typename']),
                    talentSkills: data?.talentSkills?.map((ts) => ({
                      skill: {
                        id: ts?.skill?.id?.startsWith('create')
                          ? createdSkills?.find(
                              (cs) => cs.name === ts?.skill?.name
                            )?.id
                          : ts?.skill?.id,
                      },
                    })),
                    linkedinLink: isEmpty(data.linkedinLink)
                      ? null
                      : data.linkedinLink,
                    onlineLink: isEmpty(data.onlineLink)
                      ? null
                      : data.onlineLink,
                    links,
                  },
                })
                if (talent.error) {
                  toast.error(talent.error.message)
                  return
                }
                if (talent.data.createTalent) {
                  if (jobId) {
                    await urqlClient
                      .mutation(FreelancerCreateEngagementDocument, {
                        data: {
                          jobId,
                          talentId: talent.data.createTalent.id,
                          availabilityInfo: talentAvailability,
                          revisions: [revision],
                        },
                      })
                      .toPromise()
                    setJobId(undefined)
                  }
                  await urqlClient
                    .mutation(TalentProfileCreateDocument, {
                      data: {
                        talentId: talent.data.createTalent.id,
                        hourlyRate: data?.hourlyRate
                          ? `${data?.hourlyRate}`
                          : null,
                        title: 'Main',
                        isDefault: true,
                      },
                    })
                    .toPromise()
                }
                resetForm({ values: initialFormValue })
                setSubmitting(false)
                toast.success(
                  `Freelancer ${formatName(data)} successfully added!`
                )
                hide()
              } catch (e) {
                setErrors(e.message)
                toast.error(e.message)
                setSubmitting(false)
              }
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required'),
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
              country: Yup.object().nullable().required('Required'),
              onlineLink: Yup.string().nullable().url('Must be a valid URL'),
              linkedinLink: Yup.string()
                .url('Should be a valid url. Ex.: http://')
                .nullable(),
              city: Yup.string(),
              timezone: Yup.string(),
              phone: phone(),
              source: Yup.string().nullable().required('Required'),
              primaryPosition: Yup.object().nullable().required('Required'),
              hourlyRate: Yup.number().nullable().required('Required'),
              otherLinks: Yup.object({
                otherLinks: Yup.array().of(
                  Yup.string().url('Must be a valid URL').required('Required')
                ),
              }),
            })}
          >
            {({ isSubmitting, values, errors, setFieldValue }) => (
              <Form className="h-full">
                {/* header */}
                <header className="p-6">
                  <h3 className="text-3xl font-bold">New freelancer</h3>
                </header>
                {/* content */}
                <div
                  className="overflow-auto pb-12"
                  style={{ height: `calc(100% - 84px - 100px)` }}
                >
                  {/* freelancer */}
                  <Section name="Freelancer">
                    <div className="flex gap-6">
                      <FormikTextField
                        name="firstName"
                        label="First name"
                        required
                      />
                      <FormikTextField
                        name="lastName"
                        label="Last name"
                        required
                      />
                    </div>
                    <FormikTextField name="email" label="E-mail" required />
                    <FormikTextField name="phone" label="Phone" />
                    {/* check user */}
                    {!errors.email && <AddTalentCheck email={values.email} />}
                  </Section>
                  {/* more */}
                  <Section name="More">
                    <div className="grid grid-cols-2 gap-6">
                      <FormikSelectField
                        isAsync
                        name="country"
                        label="Country"
                        getOptionLabel={(o: any) => o.name}
                        getOptionValue={(o: any) => o.id}
                        defaultOptions={countryOptions}
                        loadOptions={handleCountriesLoadOptions}
                        required
                        styles={{
                          menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                        }}
                      />
                      <FormikTextField name="city" label="City" />
                    </div>
                    <FormikSelect
                      label="Timezone"
                      name="timezone"
                      placeholder={'Select timezone'}
                    >
                      {Object.keys(Timezones).map((tzk) => (
                        <option key={tzk} value={tzk}>
                          {Timezones[tzk]}
                        </option>
                      ))}
                    </FormikSelect>
                    <FormikTextField
                      name="onlineLink"
                      label="Online Presence"
                    />
                    <FormikTextField
                      name="linkedinLink"
                      label="LinkedIn Link"
                    />
                    <FieldArray
                      name="links.other"
                      render={(arrayHelpers) => (
                        <div className="mt-5">
                          {values.links?.other &&
                            values.links.other.length > 0 && (
                              <div className="mb-6 space-y-6">
                                {values.links.other.map((otherLink, index) => (
                                  <div key={index} className="flex">
                                    <div className="flex-1">
                                      <FormikTextField
                                        name={`links.other.${index}`}
                                        label="Other link"
                                        placeholder="http://"
                                      />
                                    </div>
                                    <button
                                      type="button"
                                      className="text-red ml-4 mt-10 inline-flex h-8 w-8 items-center justify-center"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <TrashIcon className="h-4 w-4" />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}

                          <Button
                            type="button"
                            onClick={() => arrayHelpers.push('')}
                          >
                            <PlusIcon className="mr-2 h-3 w-3" />
                            Add other link
                          </Button>
                        </div>
                      )}
                    />
                    <FormikSelectField
                      isAsync
                      name="primaryPosition"
                      label="Primary Position"
                      defaultOptions={primaryPositionOptions}
                      loadOptions={handlePrimaryPositionsLoadOptions}
                      getOptionLabel={(o: any) => o.name}
                      getOptionValue={(o: any) => o.id}
                      menuWidth={300}
                      required
                      styles={{
                        menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                      }}
                    />
                    <div className="space-y-2">
                      <FormikCreatableMultiComboBox
                        isMulti
                        name="talentSkills"
                        label="Skills"
                        defaultOptions={skillsOptions}
                        loadOptions={handleSkillsLoadOptions}
                        getOptionLabel={(o: any) => o.skill?.name}
                        getOptionValue={(o: any) => o.skill?.id}
                        placeholder="Search skills"
                        required
                        styles={{
                          menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                        }}
                        getNewOptionData={getNewCreateOptionData}
                        onCreateOption={(o) => {
                          setCreateSkill(o)
                        }}
                        isCreated={(o) => getOptionValue(o)?.includes('create')}
                        createdTooltip={(node) => (
                          <CreatableSkillTooltip node={node} />
                        )}
                        formatCreateLabel={(o: any) => o?.value}
                        creatablePromopt={
                          createSkill && (
                            <CreatableSkillPrompt
                              value={createSkill}
                              onAccept={() => {
                                setFieldValue('talentSkills', [
                                  ...values.talentSkills,
                                  getNewOptionData(createSkill),
                                ])
                                setCreateSkill(undefined)
                              }}
                              onDeny={() => setCreateSkill(undefined)}
                            />
                          )
                        }
                      />
                      {values.talentSkills?.some((rs) =>
                        rs.skill?.id?.startsWith('create')
                      ) && (
                        <CreatableSkillInfo
                          skills={values.talentSkills
                            ?.filter((rs) => rs.skill?.id?.startsWith('create'))
                            ?.map((rs) => rs.skill?.name ?? '')}
                        />
                      )}
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                      <FormikSelectField
                        name="status"
                        label="Status"
                        options={TALENT_STATUS_OPTIONS}
                        asArrayOfValues
                        styles={{
                          menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                        }}
                      />
                      <FormikSelectField
                        name="source"
                        label="Source"
                        options={AddFreelancerStatusSelectOptions}
                        asArrayOfValues
                        required
                        menuWidth={'100%'}
                        components={{
                          IndicatorSeparator: () => null,
                          Option: (props: any) => {
                            const { data } = props
                            return (
                              <components.Option
                                {...props}
                                className="leading-none"
                              >
                                {data.label}
                                <br />
                                <span className="text-gray text-xs leading-none">
                                  {TALENT_SOURCE_INFO[data.value]}
                                </span>
                              </components.Option>
                            )
                          },
                        }}
                      />
                    </div>
                    <FormikSelectField
                      isAsync
                      name="sourcedBy"
                      label="Sourced by"
                      getOptionLabel={(o: any) => formatName(o)}
                      getOptionValue={(o: any) => o.id}
                      loadOptions={handleSourcedByOptions}
                      menuWidth={300}
                      styles={{
                        menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                      }}
                    />
                    <FormikSelectField
                      isAsync
                      name="agency"
                      label="Agency"
                      getOptionLabel={(o: any) => o.name}
                      getOptionValue={(o: any) => o.id}
                      loadOptions={handleAgenciesLoadOptions}
                      menuWidth={300}
                      styles={{
                        menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                      }}
                    />
                    <FormikTextField
                      type="number"
                      name="hourlyRate"
                      label="Talent Rate"
                      prefix="&euro;"
                      suffix="/h"
                      required
                    />
                  </Section>
                  {/* job */}
                  <Card name="Assign to job">
                    <JobFinder onChange={setJobId} />
                    {jobId && (
                      <div className="mt-6">
                        {/* revision details */}
                        <div className="mt-6 grid grid-cols-2 gap-6">
                          <DateField
                            label="Earliest start date"
                            placeholder="Select"
                            value={revision.periodStart}
                            onChange={(date) =>
                              handleRevisionChange({
                                periodStart: date,
                              })
                            }
                            disablePast
                          />
                          <SelectField
                            name="availability"
                            label="Availability"
                            options={TALENT_AVAILABILITY_OPTIONS}
                            // asArrayOfValues
                            onChange={(o: any) =>
                              setTalentAvailability(o.value)
                            }
                            required
                            styles={{
                              menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                            }}
                          />
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-6">
                          <SelectField
                            label="Engagement status"
                            options={ENGAGEMENT_STATUS_GROUPED_OPTIONS}
                            menuWidth={300}
                            onChange={(so: any) =>
                              handleRevisionChange({
                                status: so.value,
                              })
                            }
                            // asArrayOfValues
                            required
                            styles={{
                              menuPortal: (b) => ({ ...b, zIndex: 10000 }),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Card>
                </div>
                {/* footer */}
                <footer className="border-gray-lightest absolute bottom-0 flex w-full border-t bg-white p-6">
                  <ButtonSecondary onClick={hide}>Cancel</ButtonSecondary>
                  <FormikSubmitButton
                    className="ml-auto"
                    isLoading={isSubmitting}
                  >
                    Add freelancer
                  </FormikSubmitButton>
                </footer>
              </Form>
            )}
          </Formik>
        </DrawerBase>
      )}
    </>
  )
}

function Section({ children, name }: PropsWithChildren<{ name: string }>) {
  return (
    <section className="relative">
      <header className="bg-gray-lightest sticky top-0 z-10 px-6 py-3">
        <h5 className="text-lg font-semibold">{name}</h5>
      </header>
      <div className="space-y-6 px-6 py-6">{children}</div>
    </section>
  )
}

function Card({ children, name }: PropsWithChildren<{ name: string }>) {
  return (
    <section className="bg-gray-lightest relative m-1 rounded-md p-6">
      <header className="top-0">
        <h5 className="font-semibold">{name}</h5>
      </header>
      <div className="mt-6">{children}</div>
    </section>
  )
}
