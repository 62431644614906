export const EngagementsIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.38086H25"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6087 5.19043H23.9565C24.2333 5.19043 24.4987 5.3008 24.6944 5.49727C24.8901 5.69374 25 5.9602 25 6.23805V21.9523C25 22.2302 24.8901 22.4966 24.6944 22.6931C24.4987 22.8896 24.2333 23 23.9565 23H2.04348C1.76673 23 1.50132 22.8896 1.30563 22.6931C1.10994 22.4966 1 22.2302 1 21.9523V6.23805C1 5.9602 1.10994 5.69374 1.30563 5.49727C1.50132 5.3008 1.76673 5.19043 2.04348 5.19043H10.3913"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1304 14.6191H22.3912"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1304 17.7617H19.7825"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.005 20.3812C13.5793 19.7212 12.1675 19.2414 10.3998 18.5845C9.90103 18.3981 9.98242 17.0906 10.2036 16.8465C10.5555 16.4639 10.8227 16.011 10.9878 15.5173C11.1529 15.0237 11.2121 14.5006 11.1615 13.9823C11.1918 13.6594 11.1534 13.3337 11.049 13.0267C10.9445 12.7198 10.7764 12.4386 10.5556 12.2017C10.3349 11.9649 10.0666 11.7777 9.76849 11.6527C9.47037 11.5277 9.14921 11.4676 8.82624 11.4764C8.50328 11.4676 8.18212 11.5277 7.884 11.6527C7.58588 11.7777 7.31759 11.9649 7.09685 12.2017C6.87612 12.4386 6.70797 12.7198 6.60352 13.0267C6.49907 13.3337 6.4607 13.6594 6.49094 13.9823C6.44038 14.5006 6.4996 15.0237 6.66469 15.5173C6.82978 16.011 7.09702 16.4639 7.44885 16.8465C7.67007 17.0906 7.75146 18.3981 7.25268 18.5845C5.48711 19.2414 4.0732 19.7212 3.64746 20.3812"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.609 6.7619C15.609 6.90083 15.554 7.03406 15.4562 7.13229C15.3583 7.23053 15.2256 7.28571 15.0873 7.28571H10.9133C10.775 7.28571 10.6423 7.23053 10.5444 7.13229C10.4466 7.03406 10.3916 6.90083 10.3916 6.7619V3.61905C10.3916 2.92443 10.6664 2.25827 11.1557 1.7671C11.6449 1.27593 12.3084 1 13.0003 1C13.6922 1 14.3557 1.27593 14.8449 1.7671C15.3341 2.25827 15.609 2.92443 15.609 3.61905V6.7619Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
