export const TEAM_ENVIRONMENT_OPTIONS = [
  {
    label: 'Startup',
    value: 'Startup',
  },
  {
    label: 'Scale-up',
    value: 'ScaleUp',
  },
  {
    label: 'Corporate',
    value: 'Corporate',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const PROJECT_MANAGEMENT_METHODOLOGY = [
  {
    label: 'SCRUM',
    value: 'Scrum',
  },
  {
    label: 'Kanban',
    value: 'Kanban',
  },
  {
    label: 'Waterfall',
    value: 'Waterfall',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]
