import {
  FormikCheckbox,
  FormikRichTextEditor,
  FormikSelectField,
  FormikTextarea,
  FormikTextField,
} from '@upper/formik'
import {
  HubspotCompaniesSearchDocument,
  RoleKey,
  UsersDocument,
} from '@upper/graphql/internal'
import { FormLabel } from '@upper/ui'
import { formatName, pick } from '@upper/utils'
import { useField } from 'formik'
import { useCallback } from 'react'
import { useClient } from 'urql'
import { SideFormSectionRow } from '../../../components/side-form-section-row'
import { FormSectionHeader } from './multi-step-manager'

type Props = {
  prefix?: string
  handleEnter: (key: string) => void
  handleLeave: (key: string) => void
}
export function JobCompanyForm({
  prefix = 'company',
  handleEnter,
  handleLeave,
}: Props) {
  const urqlClient = useClient()

  const loadHubspotCompaniesOptions = useCallback(
    async (value) => {
      try {
        const result = await urqlClient
          .query(HubspotCompaniesSearchDocument, { term: value })
          .toPromise()
        return result.data?.companiesHubspotSearch?.results || []
      } catch (e) {
        console.log(e)
      }
    },
    [urqlClient]
  )

  const loadEMOptions = useCallback(
    async (value) => {
      try {
        const result = await urqlClient
          .query(UsersDocument, {
            filters: { roles: [RoleKey.Em], name: value },
          })
          .toPromise()
        return (
          result.data?.users?.map((u) =>
            pick(u, 'id', 'firstName', 'lastName')
          ) || []
        )
      } catch (e) {
        console.log(e)
      }
    },
    [urqlClient]
  )

  const [companyField] = useField(prefix)

  return (
    <>
      <FormSectionHeader
        id="details"
        onEnter={() => handleEnter('details')}
        onLeave={() => handleLeave('details')}
      >
        Details
      </FormSectionHeader>
      <div className="@xl/main:grid-cols-3 @xl/main:gap-x-2 grid grid-cols-1 gap-y-2">
        <div className="space-y-2">
          <FormikTextField
            name={`${prefix}.name`}
            label="Company name"
            required
          />
          <FormikTextField
            name={`${prefix}.url`}
            label="Website URL"
            placeholder="https://..."
            required
          />
        </div>
        <FormikRichTextEditor
          className="col-span-2"
          name={`${prefix}.description`}
          label="Company description"
          required
          helpText="What business does, where it operates, etc."
        />
      </div>
      {/* representatives */}
      <FormSectionHeader
        id="representative"
        onEnter={() => handleEnter('representative')}
        onLeave={() => handleLeave('representative')}
      >
        Representative
      </FormSectionHeader>
      <div className="space-y-6">
        <SideFormSectionRow>
          <div className="grid grid-cols-2 gap-2">
            <FormikTextField
              name={`${prefix}.representative.firstName`}
              label="First name"
              required
            />
            <FormikTextField
              name={`${prefix}.representative.lastName`}
              label="Last name"
              required
            />
          </div>
          <div className="mt-6 grid grid-cols-2 gap-2">
            <FormikTextField
              name={`${prefix}.representative.email`}
              label="E-mail"
              required
            />
            <FormikTextField
              name={`${prefix}.representative.phone`}
              label="Phone"
            />
          </div>
          <div className="mt-6 grid grid-cols-1 gap-2">
            <FormikTextField
              name={`${prefix}.representative.role`}
              label="Role"
            />
          </div>
        </SideFormSectionRow>
        <SideFormSectionRow>
          <FormikSelectField
            name={`${prefix}.em`}
            label="EM"
            isAsync
            isSearchable
            required
            getOptionLabel={(o: any) => formatName(o)}
            getOptionValue={(o: any) => o.id}
            loadOptions={loadEMOptions}
          />
        </SideFormSectionRow>
      </div>
      {/* more details */}
      <FormSectionHeader
        id="more-details"
        onEnter={() => handleEnter('more-details')}
        onLeave={() => handleLeave('more-details')}
      >
        More details
      </FormSectionHeader>
      <div>
        <FormikTextarea
          className="col-span-2"
          name={`${prefix}.legalNotes`}
          label="Legal particularities"
          helpText="(custom agreements)"
        />
        <div className="space-y-6">
          <FormikTextarea
            name={`${prefix}.procurementNotes`}
            label="Procurement requirements"
          />
          <FormikCheckbox name={`${prefix}.hasFreelancers`}>
            Company has freelancers in the team
          </FormikCheckbox>
          <FormikCheckbox name={`${prefix}.hadFreelancers`}>
            Company has worked with freelancers in the past
          </FormikCheckbox>
        </div>
        {/* integrations */}
      </div>
      <FormSectionHeader
        id="hubspot"
        onEnter={() => handleEnter('hubspot')}
        onLeave={() => handleLeave('hubspot')}
      >
        Hubspot
      </FormSectionHeader>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <FormLabel>Hubspot Company</FormLabel>
          <FormikCheckbox
            name={`${prefix}.hubspot.shouldCreate`}
            className="mt-5"
          >
            Create new Hubspot Company
          </FormikCheckbox>
        </div>
        {!companyField.value.hubspot?.shouldCreate && (
          <FormikSelectField
            isAsync
            label="Select Hubspot company"
            name={`${prefix}.hubspot.selected`}
            getOptionLabel={(o: any) => o.name}
            getOptionValue={(o: any) => o.id}
            loadOptions={loadHubspotCompaniesOptions}
            isClearable
          />
        )}
      </div>
    </>
  )
}
