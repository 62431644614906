export const TimesheetsIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3045 15.6074H18.1831C17.8579 15.6076 17.543 15.721 17.2924 15.928C17.0417 16.135 16.8708 16.4228 16.8091 16.742C16.7474 17.0612 16.7987 17.392 16.9542 17.6775C17.1097 17.963 17.3597 18.1855 17.6613 18.3069L19.8151 19.1678C20.1174 19.2887 20.3681 19.5112 20.5241 19.7969C20.6801 20.0827 20.7316 20.4139 20.6699 20.7336C20.6081 21.0532 20.4369 21.3414 20.1857 21.5485C19.9344 21.7556 19.6189 21.8687 19.2933 21.8683H17.174"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7393 22.9126V21.8691"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7393 15.6089V14.5654"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7394 25.0003C22.1972 25.0003 25.0003 22.1972 25.0003 18.7394C25.0003 15.2816 22.1972 12.4785 18.7394 12.4785C15.2816 12.4785 12.4785 15.2816 12.4785 18.7394C12.4785 22.1972 15.2816 25.0003 18.7394 25.0003Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3913 19.7816H2.04348C1.76673 19.7816 1.50132 19.6717 1.30563 19.476C1.10994 19.2803 1 19.0149 1 18.7381V4.12942C1 3.85267 1.10994 3.58726 1.30563 3.39157C1.50132 3.19588 1.76673 3.08594 2.04348 3.08594H20.8261C21.1028 3.08594 21.3682 3.19588 21.5639 3.39157C21.7596 3.58726 21.8696 3.85267 21.8696 4.12942V10.912"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.21729 1V6.21739"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6523 1V6.21739"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.30469H21.8696"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
