export enum Timezones {
  'GMT-12:00' = '(GMT-12:00) International Date Line West',
  'GMT-11:00' = '(GMT-11:00) Midway Island, Samoa',
  'GMT-10:00' = '(GMT-10:00) Hawaii',
  'GMT-09:30' = '(GMT-09:30) Taiohae',
  'GMT-09:00' = '(GMT-09:00) Alaska',
  'GMT-08:00' = '(GMT-08:00) Pacific Time (US & Canada)',
  'GMT-07:00' = '(GMT-07:00) Mountain Time (US & Canada), Arizona',
  'GMT-06:00' = '(GMT-06:00) Central Time (US & Canada), Mexico City',
  'GMT-05:00' = '(GMT-05:00) Eastern Time (US & Canada), Bogota, Lima',
  'GMT-04:30' = '(GMT-04:30) Caracas',
  'GMT-04:00' = '(GMT-04:00) Atlantic Time (Canada)',
  'GMT-03:30' = '(GMT-03:30) Newfoundland',
  'GMT-03:00' = '(GMT-03:00) Buenos Aires, Georgetown',
  'GMT-02:00' = '(GMT-02:00) Mid-Atlantic',
  'GMT-01:00' = '(GMT-01:00) Azores',
  'GMT+00:00' = '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
  'GMT+01:00' = '(GMT+01:00) Berlin, Amsterdam, Bern, Rome, Stockholm, Vienna',
  'GMT+02:00' = '(GMT+02:00) Bucharest, Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'GMT+03:00' = '(GMT+03:00) Baghdad, Riyadh, Moscow, St. Petersburg, Volgograd',
  'GMT+03:30' = '(GMT+03:30) Tehran',
  'GMT+04:00' = '(GMT+04:00) Yerevan',
  'GMT+04:30' = '(GMT+04:30) Kabul',
  'GMT+05:00' = '(GMT+05:00) Islamabad, Karachi, Tashkent, Yekaterinburg',
  'GMT+05:30' = '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  'GMT+05:45' = '(GMT+05:45) Kathmandu, Pokhara',
  'GMT+06:00' = '(GMT+06:00) Astana, Dhaka',
  'GMT+06:30' = '(GMT+06:30) Yangon (Rangoon)',
  'GMT+07:00' = '(GMT+07:00) Bangkok, Hanoi, Jakarta',
  'GMT+08:00' = '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  'GMT+08:45' = '(GMT+08:45) Eucla',
  'GMT+09:00' = '(GMT+09:00) Seoul',
  'GMT+09:30' = '(GMT+09:30) Adelaide',
  'GMT+10:00' = '(GMT+10:00) Canberra, Melbourne, Sydney',
  'GMT+10:30' = '(GMT+10:30) Lord Howe Island',
  'GMT+11:00' = '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
  'GMT+12:00' = '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka',
  'GMT+12:45' = '(GMT+12:45) Chatham Islands',
  'GMT+13:00' = "(GMT+13:00) Apia, Nuku'alofa",
  'GMT+14:00' = '(GMT+14:00) Line Islands, Tokelau',
}
