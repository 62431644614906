import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

const STEP_BADGE_LABEL = {
  createNew: 'New',
  selectExisting: 'Existing',
  edit: 'Edit',
}

const STEP_BADGE_COLORS = {
  createNew: 'bg-green-100 text-green-600',
  selectExisting: 'bg-blue-100 text-blue-600',
  edit: 'bg-yellow-100 text-yellow-600',
}

export function StepBadge({
  children,
  step,
}: PropsWithChildren<{ step: 'createNew' | 'selectExisting' | 'edit' }>) {
  return (
    <span
      className={twMerge(
        '!text-xxs flex rounded-full px-1.5 py-1',
        STEP_BADGE_COLORS[step]
      )}
    >
      {children ? children : STEP_BADGE_LABEL[step]}
    </span>
  )
}
