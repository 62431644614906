import {
  JobCommitment,
  JobContractType,
  JobLocation,
  JobPriority,
  JobStatus,
} from '@upper/graphql/internal'
import { JOB_DURATION } from '../../const/job'
import {
  JobCompanyInput as JobAddCompanyInput,
  JobInfoInput as JobAddJobInput,
  JobProjectInput as JobAddProjectInput,
} from './type'

export const ADD_JOB_COMPANY_INPUT_DEFAULT: JobAddCompanyInput & {
  hubspot: {
    shouldCreate: boolean
    selected: { id: string, name: string } | null
  }
} = {
  name: '',
  url: '',
  description: '',
  hasFreelancers: false,
  hadFreelancers: false,
  legalNotes: '',
  procurementNotes: '',
  em: null,
  representative: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    phone: '',
  },
  hubspot: {
    shouldCreate: false,
    selected: null,
  },
}

export const ADD_JOB_PROJECT_INPUT_DEFAULT: JobAddProjectInput = {
  name: '',
  description: '',
  communicationModel: '',
  onboardingProcess: '',
  phase: null,
  platforms: null,
  managementMethodology: null,
  managementMethodologyOther: null,
  state: null,
  teamState: null,
  teamEnvironment: null,
  teamEnvironmentOther: null,
  teamSize: null,
  skills: null,
  contact: { email: '', lastName: '', firstName: '', phone: '', companyId: '' },
  contactId: '',
}

export const ADD_JOB_JOB_INPUT_DEFAULT: JobAddJobInput = {
  name: '',
  teamSize: 1,
  description: '',
  requiredSkills: [],
  niceToHaveSkills: [],
  requiredExperience: '',
  niceToHaveExperience: '',
  acceptanceProcess: '',
  roleGoals: '',
  mindset: '',
  contractType: JobContractType.Retainer,
  languages: [],
  commitment: JobCommitment.FullTime,
  commitmentInfo: '',
  startDate: null,
  length: JOB_DURATION[0].value,
  hourlyRateBudget: null,
  annualSalaryBudget: null,
  priority: JobPriority.None,
  isPausedSourcing: false,
  status: JobStatus.Draft,
  position: null,
  location: JobLocation.Remote,
  locationOnSite: '',
  dealSource: null,
  dealType: null,
  locationOnSitePercentage: '',
  hubspotDealId: null,
  em: null,
}
